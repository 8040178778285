import { AUTH_PHONE } from 'auth/phone'
import { TOKEN_KEYS } from 'auth/tokenKeys'
import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { getAuth, getAuthPhone } from 'store/features/authorization/selectors'
import { useAppSelector } from 'store/hooks'
import { useTheme } from 'styled-components'
import { setCookie } from 'utilities/cookie'

import { IconWrap, LogoutIcon, StyledFlex, StyledTypography, Wrapper } from './styles'

export const Phone = () => {
  const theme = useTheme()
  const router = useRouter()

  const isAuth = useAppSelector(getAuth)
  const phone = useAppSelector(getAuthPhone)

  const logout = () => {
    setCookie(TOKEN_KEYS.ACCESS_TOKEN, '')
    setCookie(TOKEN_KEYS.REFRESH_TOKEN, '')
    setCookie(AUTH_PHONE.PHONE, '')

    void router.push('/?refund')
  }

  const renderPhone = () => (
    <Flex container alignItems='center' columnGap={16}>
      <StyledTypography
        color={theme.palette.text.ALTERNATIVE}
        typography={theme.typography.variants.content.BOLD}
      >
        {phone}
      </StyledTypography>
      <IconWrap>
        <LogoutIcon
          onClick={logout}
          icon={theme.icons.core.Logout}
          color={theme.palette.core.WHITE}
          size={20}
        />
      </IconWrap>
    </Flex>
  )

  const renderLink = () => (
    <Link href='/?refund' passHref>
      <StyledFlex container alignItems='center' columnGap={16}>
        <IconWrap>
          <Icon icon={theme.icons.core.User} color={theme.palette.core.WHITE} size={20} />
        </IconWrap>
        <StyledTypography
          color={theme.palette.text.ALTERNATIVE}
          typography={theme.typography.variants.content.BOLD}
        >
          <a>Личный кабинет</a>
        </StyledTypography>
      </StyledFlex>
    </Link>
  )

  const render = () => (isAuth ? renderPhone() : renderLink())

  return <Wrapper>{render()}</Wrapper>
}
