import { ProductCode, TariffCode } from 'interfaces/Codes'
import { InternalProduct } from 'interfaces/InternalProducts'
import { PassengerTypes } from 'interfaces/Passenger'

export enum Timezone {
  Almaty = 'Asia/Almaty',
  Atyrau = 'Asia/Atyrau',
}

export enum BookingDocument {
  IdentityCard = '1',
  Passport = '2',
  BirthCertificate = '3',
  MilitaryId = '4',
  TemporaryIdentityCard = '5',
  DiplomaticPassport = '6',
  ServicePassport = '7',
  SeafarerIdentityCard = '8',
  ForeignDocument = '9',
  CertificateOfDisability = '0',
  Residence = '10',
  ReturnCertificate = '11',
  ReleaseCertificate = '12',
  IdentityCardWithoutCitizenship = '13',
}

export enum BookingState {
  Provisional = 'provisional',
  Filled = 'filled',
  Paid = 'paid',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
  Refunded = 'refunded',
  PartiallyRefunded = 'partially_refunded',
}

export interface UnfinishedBooking {
  id: string
  expires: string
}

export interface BookingPassengersRequestData {
  phoneNumber: string
  email: string
  smsNotification: boolean
  passengers: Array<BookingPassenger>
}

export interface SuccessBookingPassengerProduct {
  carriage?: string
  code: ProductCode
  name: string
  price: number
  seat?: string
}

export interface SuccessBookingPassenger {
  uuid: string
  products: Array<SuccessBookingPassengerProduct>
}

export enum BookingCreationWarningMessageCode {
  SeatChanged = 13090,
}

export interface BookingCreationWarningMessage {
  code?: BookingCreationWarningMessageCode
}

export interface SuccessBookingCreationResponse {
  bookingNumber: string
  inboundPassengers: Record<string, SuccessBookingPassenger>
  outboundPassengers: Record<string, SuccessBookingPassenger>
  messages: Array<BookingCreationWarningMessage>
}

export interface BookingUpdateResponse {
  bookingNumber: string
  bookingStatus: BookingState
}

export interface BookingStation {
  shortCode: string
  name: string
  uic: string
  timezone: string
}

export interface BookingJourneySegment {
  arrivalDateTime: string
  departureDateTime: string
  arrivalStation: BookingStation
  departureStation: BookingStation
  serviceName: string
}

export interface BookingProduct {
  name: string
  price: number
  passengerId: string
  code: ProductCode
  seatCarriage: string
  seatCompartment: string
  seatNumber: string
  uuid: string
  tariffCode: TariffCode
  cancelled?: boolean
  isRefundPending: boolean
  serviceIdentifier: string
}

export interface BookingTariffSegment {
  journeySegment: BookingJourneySegment
  requiredProducts: Array<BookingProduct>
  additionalProducts: Array<BookingProduct>
  validityService: string
  serviceIdentifier: string
}

export interface TravelDocument {
  // type: BookingDocument // deprecated
  docType: BookingDocument
  documentNumber: string
  expiryDate: string
  countryOfIssue: string
  personalIdentificationNumber: string
}

export interface BookingPassenger {
  birthDate: string
  email: string
  firstName: string
  patronymic?: string
  gender: string
  sqillsId: string
  lastName: string
  phoneNumber: string
  refId: number
  type: PassengerTypes
  sqillsUuid: string
  travelDocument: TravelDocument
}

export interface FormikBookingPassenger extends BookingPassenger {
  patronymic: string
  disabilityConfirmationNumber: string
  document: BookingDocument
  documentNumber: string
  identityNumber: string
}

export interface BookingCustomer {
  email: string
  phoneNumber: string
}

export interface Booking {
  createTimestamp: string
  expiresAt: string
  customer: BookingCustomer | null
  bookingNumber: string
  inboundBookingTariffSegments: Array<BookingTariffSegment>
  outboundBookingTariffSegments: Array<BookingTariffSegment>
  internalProducts: Array<InternalProduct>
  passengers: Array<BookingPassenger>
  smsNotifications: boolean
  status: BookingState
  totalPrice: number
  isRefundPending: boolean
}

export interface Route {
  departureStation: string
  departureDateTime: string
  departureTimezone: string
  arrivalStation: string
  arrivalDateTime: string
  arrivalTimezone: string
}
