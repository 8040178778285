import { CarriageSeat } from 'interfaces/Carriage'
import { ProductCode } from 'interfaces/Codes'
import { Passenger } from 'interfaces/Passenger'
import { Ticket } from 'interfaces/Ticket'
import { getSeatInventoryClass } from 'store/features/passengers/utilities/getSeatInventoryClass'

export const getSeatPrice = (
  ticket: Ticket | null,
  seat?: CarriageSeat,
  passenger?: Passenger,
  faresToIgnore?: Array<ProductCode>,
  useBedding = true,
  isCoupe = false
) => {
  if (!seat || !ticket) return 0

  const inventoryClass = getSeatInventoryClass(seat, ticket)
  if (!inventoryClass) {
    return 0
  }

  return inventoryClass.items.reduce((acc, item) => {
    if (item.productCode === ProductCode.BEDLIN && !useBedding && !isCoupe) return acc
    const fare =
      item.passengerFares.find(f => f.passengerId === passenger?.id) ||
      item.passengerFares.find(f => f.discounts.length === 0) ||
      item.passengerFares[0]
    if (!fare || (faresToIgnore && faresToIgnore.includes(fare.productCode))) return acc
    return acc + fare.price
  }, 0)
}
