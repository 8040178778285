import styled from 'styled-components'

export const Wrapper = styled.div`
  .Toastify {
    &__toast {
      border-radius: 5px;
      padding: 6px;
      &-container {
        width: 405px;
      }
      &-body {
        padding: 18px 14px;
        font-size: 14px;
        line-height: 130%;
        color: ${({ theme }) => theme.palette.core.BLACK};
      }
      &-icon {
        margin-right: 19px;
      }
    }
    &__progress-bar {
      background: ${({ theme }) => theme.palette.secondary.MAIN};
    }
  }
`
