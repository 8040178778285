import { DefaultTheme } from 'styled-components'

import { icons } from './icons'
import { palette } from './palette'
import { shadow } from './shadow'
import { Sizes, typography, Weights } from './typography'

export const theme: DefaultTheme = {
  palette: {
    core: palette.Core,
    primary: palette.Primary,
    secondary: palette.Secondary,
    text: palette.Text,
  },
  typography: {
    sizes: Sizes,
    weights: Weights,
    variants: {
      title: typography.Title,
      description: typography.Description,
      content: typography.Content,
      button: typography.Button,
    },
  },
  shadow: {
    primary: shadow.Primary,
  },
  icons,
}
