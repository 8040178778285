import * as Sentry from '@sentry/nextjs'

const DSN = process.env.SENTRY_DSN
const ENVIRONMENT = process.env.SENTRY_ENVIRONMENT

Sentry.init({
  dsn: DSN,
  tracesSampleRate: 1.0,
  environment: ENVIRONMENT,
})
