import { createIcon } from 'styles/theme/icons/createIcon'

export const Add = createIcon(
  16,
  16,
  'M12.6666 8.66683H8.66659V12.6668H7.33325V8.66683H3.33325V7.3335H7.33325V3.3335H8.66659V7.3335H12.6666V8.66683Z'
)

export const Remove = createIcon(
  16,
  16,
  'M12.6666 8.66683H8.66659H7.33325H3.33325V7.3335H7.33325H8.66659H12.6666V8.66683Z'
)
