import { ToastType } from 'interfaces/Toast'
import { Translate } from 'next-translate'
import { toast } from 'react-toastify'

export const toastByType = (type: ToastType, t: Translate, extra?: Record<string, unknown>) => {
  if (type === ToastType.BookingDataFillExpired) {
    const content = (
      <>
        <div>{t('toasts:bookingFailure.title')}</div>
        <div>{t('toasts:bookingFailure.label')}</div>
      </>
    )
    toast(content)
  }

  if (type === ToastType.SeatChangedDuringBooking) {
    const content = <div>{t('toasts:seatChangedDuringBooking.message')}</div>
    toast(content, { autoClose: 30 * 1000 })
  }

  if (type === ToastType.UnexpectedPaymentApprove) {
    const content = <div>{t('toasts:unexpectedPayment.message')}</div>
    toast(content, { autoClose: 10 * 1000 })
  }

  if (type === ToastType.BookingAlreadyConfirmed) {
    const content = <div>{t('toasts:bookingAlreadyConfirmed.message')}</div>
    toast(content, { autoClose: 20 * 1000 })
  }

  if (type === ToastType.EmailTicketsFailure) {
    const content = <div>{t('toasts:emailTicketsFailure.message')}</div>
    toast(content, { autoClose: 5 * 1000 })
  }

  if (type === ToastType.UnexpectedError) {
    const content = <div>{t('toasts:unexpectedError.message')}</div>
    toast(content, { autoClose: 5 * 1000 })
  }

  if (type === ToastType.TooFastPayment) {
    const content = <div>{t('toasts:tooFastPayment.message')}</div>
    toast(content, { autoClose: 5 * 1000 })
  }

  if (type === ToastType.RefundWarning) {
    const content = <div>{t('toasts:refundWarning.message')}</div>
    toast(content, { autoClose: 10 * 1000 })
  }

  if (type === ToastType.EmailTicketSuccessfullySent) {
    const content = <div>{t('toasts:emailTicketsSuccess.message', extra)}</div>
    toast(content, { autoClose: 5 * 1000 })
  }

  if (type === ToastType.TicketIsNotReady) {
    const content = <div>{t('toasts:ticketIsNotReady.message', extra)}</div>
    toast(content, { autoClose: 5 * 1000 })
  }

  if (type === ToastType.Copied) {
    const content = <div>{t('toasts:copied.message', extra)}</div>
    toast(content, { autoClose: 5 * 1000 })
  }

  if (type === ToastType.Updated) {
    const content = <div>{t('toasts:updated.message', extra)}</div>
    toast(content, { autoClose: 5 * 1000 })
  }
}
