import { createSlice } from '@reduxjs/toolkit'
import { Station } from 'interfaces/Station'

export interface StationsState {
  entities: Array<Station>
  isLoading: boolean
  isError: boolean
}

export const initialState: StationsState = {
  entities: [],
  isLoading: true,
  isError: false,
}

export const stationsSlice = createSlice({
  name: 'stations',
  initialState,
  reducers: {},
})

export const { reducer } = stationsSlice
