import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import { Typography } from 'components/ui/Typography'
import styled from 'styled-components'

export const Wrapper = styled.div`
  @media screen and (max-width: 1180px) {
    white-space: nowrap;
  }
`

export const StyledFlex = styled(Flex)`
  cursor: pointer;
`

export const IconWrap = styled.div`
  @media screen and (max-width: 1180px) {
    > div {
      width: 25px;
      height: 25px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`

export const LogoutIcon = styled(Icon)`
  cursor: pointer;
`

export const StyledTypography = styled(Typography)`
  @media screen and (max-width: 1180px) {
    font-size: 16px;

    a {
      font-weight: bold;
    }
  }
`
