import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { SERVICE_TOKEN } from 'core/env'

export const clearBookingPayment = async (bookingId: string) => {
  await baseAxios.get(URLS.clearBookingPayment(bookingId), {
    headers: {
      'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
    },
  })
}
