import styled, { css } from 'styled-components'
import { AnyColor } from 'styles/theme/palette'
import { pixelize } from 'utilities/pixelize'

interface Props {
  color?: AnyColor
  width?: number
  height?: number
  colorProperty: 'fill' | 'stroke'
}

export const Wrapper = styled.div<Props>`
  display: inline-block;
  ${({ width }) => width && `width: ${pixelize(width)};`}
  ${({ height }) => height && `height: ${pixelize(height)};`}
  ${({ color, colorProperty }) =>
    color &&
    css`
      ${colorProperty}: ${color};
    `}
`
