import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCalendar } from 'api/requests/getCalendar'
import { format, parseJSON } from 'date-fns'
import { Calendar } from 'interfaces/Calendar'
import { PassengerTypes } from 'interfaces/Passenger'

interface Params {
  origin: string
  destination: string
  outboundDate: Date
  inboundDate?: Date
  passengers: Record<PassengerTypes, number>
}

const trasformCalendar = (calendar: Calendar): Calendar => {
  if (calendar.inbound.length === calendar.outbound.length) return calendar
  return {
    outbound: calendar.outbound,
    inbound: calendar.inbound.filter(inboundItem =>
      calendar.outbound.find(
        outboundItem =>
          format(parseJSON(inboundItem.date), 'dd-MM-yyyy') ===
          format(parseJSON(outboundItem.date), 'dd-MM-yyyy')
      )
    ),
  }
}

export const fetchPricesCalendar = createAsyncThunk(
  'fetchPricesCalendar',
  async (params: Params) => {
    const response = await getCalendar(params)
    return trasformCalendar(response)
  }
)
