export enum Core {
  WHITE = '#FFFFFF',
  BLACK = '#212121',
  GRAY = '#F4F4F4',
  BLUE = '#007AFF',
  DEEP_GRAY = '#757575',
  LIGHT_GRAY = '#BDBDBD',
  VERY_LIGHT_GRAY = '#F8F4F4',
  VERY_LIGHT_RED = '#FFF2F3',
  SUCCESS = '#43A047',
  ERROR = '#E53935',
  TRANSPARENT = 'transparent',
}

export enum Primary {
  MAIN = '#1E3656',
}

export enum Secondary {
  MAIN = '#E43C4A',
}

export enum Text {
  MAIN = '#212121',
  DEEP_GRAY = '#757575',
  LIGHT_GRAY = '#BDBDBD',
  VERY_LIGHT_GRAY = '#F8F4F4',
  ALTERNATIVE = '#FFFFFF',
}
