import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Schedule } from 'interfaces/Schedule'
import { SelectSeatStepType } from 'interfaces/SelectSeatStepType'

export interface ScheduleState {
  outbound: {
    schedule: Array<Schedule>
  }
  inbound: {
    schedule: Array<Schedule>
  }
}

export const initialState: ScheduleState = {
  outbound: {
    schedule: [],
  },
  inbound: {
    schedule: [],
  },
}

interface Action {
  type: SelectSeatStepType
  schedule: Schedule
}

export const scheduleSlice = createSlice({
  name: 'scheduleSlice',
  initialState,
  reducers: {
    setSchedule: (state, action: PayloadAction<Action>) => {
      const { type, schedule } = action.payload

      return {
        ...state,
        [type]: {
          ...state[type],
          schedule: [...state[type].schedule, schedule],
        },
      }
    },
  },
})

export const { setSchedule } = scheduleSlice.actions
export const { reducer } = scheduleSlice
