/* eslint-disable prefer-destructuring */

// CORE
export const API_URL = process.env.NEXT_PUBLIC_API_URL ?? 'https://staging.bile-bile.kz/api/v1/'
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://staging.bile-bile.kz'
export const GTM_KEY = process.env.GTM_KEY ?? ''

// CONFIGURATION
export const SALES_DEPTH_LIMIT = Number(process.env.NEXT_PUBLIC_SALES_DEPTH_LIMIT) || 45
export const MAX_TICKETS_TO_BUY = Number(process.env.NEXT_PUBLIC_MAX_TICKETS_TO_BUY) || 12

// SERVICE AUTH
export const SERVICE_TOKEN = process.env.NEXT_PUBLIC_SERVICE_TOKEN || 'TOKEN_NOT_SET'
