import styled from 'styled-components'

export const Wrapper = styled.div`
  display: none;

  @media screen and (max-width: 1180px) {
    display: block;
  }
`

export const IconWrap = styled.button`
  border: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
`
