import { HTMLAttributes } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'
import { AnyColor } from 'styles/theme/palette'
import { AnyTypography, typography } from 'styles/theme/typography'
import { pixelize } from 'utilities/pixelize'

interface Props extends HTMLAttributes<HTMLDivElement> {
  lh?: number | string
  typography: AnyTypography
  color: AnyColor
}

const titleH1 = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.XXL,
  weight: theme.typography.weights.BOLD,
})

const titleH2 = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.XL,
  weight: theme.typography.weights.BOLD,
})

const titleH3 = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.LG,
  weight: theme.typography.weights.BOLD,
})

const contentMain = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.DEFAULT,
  weight: theme.typography.weights.REGULAR,
})

const contentBold = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.DEFAULT,
  weight: theme.typography.weights.BOLD,
})

const descriptionMain = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.XS,
  weight: theme.typography.weights.REGULAR,
})

const descriptionBold = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.XS,
  weight: theme.typography.weights.BOLD,
})

const buttonMain = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.DEFAULT,
  weight: theme.typography.weights.BOLD,
})

const buttonLight = (theme: DefaultTheme) => ({
  size: theme.typography.sizes.DEFAULT,
  weight: theme.typography.weights.REGULAR,
})

const getTypographySizes = (t: AnyTypography, theme: DefaultTheme) => {
  switch (t) {
    case typography.Title.H1:
      return titleH1(theme)
    case typography.Title.H2:
      return titleH2(theme)
    case typography.Title.H3:
      return titleH3(theme)
    case typography.Content.BOLD:
      return contentBold(theme)
    case typography.Description.MAIN:
      return descriptionMain(theme)
    case typography.Description.BOLD:
      return descriptionBold(theme)
    case typography.Button.MAIN:
      return buttonMain(theme)
    case typography.Button.LIGHT:
      return buttonLight(theme)
    case typography.Content.MAIN:
    default:
      return contentMain(theme)
  }
}

export const Wrapper = styled.span<Props>`
  cursor: inherit;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -0.4px;
  ${({ lh }) =>
    typeof lh !== 'undefined' &&
    css`
      line-height: ${lh};
    `}
  ${({ typography: t, color, theme }) => {
    const { weight, size } = getTypographySizes(t, theme)

    return css`
      color: ${color};
      font-size: ${pixelize(size)};
      font-weight: ${weight};
    `
  }}
`
