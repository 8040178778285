import { createIcon } from '../createIcon'

export const Loader = createIcon(
  100,
  100,
  <>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73'>
      <animate
        attributeName='opacity'
        begin='-0.5756578947368421s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73' transform='rotate(45 50 50)'>
      <animate
        attributeName='opacity'
        begin='-0.4934210526315789s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73' transform='rotate(90 50 50)'>
      <animate
        attributeName='opacity'
        begin='-0.41118421052631576s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73' transform='rotate(135 50 50)'>
      <animate
        attributeName='opacity'
        begin='-0.32894736842105265s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73' transform='rotate(180 50 50)'>
      <animate
        attributeName='opacity'
        begin='-0.24671052631578946s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73' transform='rotate(225 50 50)'>
      <animate
        attributeName='opacity'
        begin='-0.16447368421052633s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73' transform='rotate(270 50 50)'>
      <animate
        attributeName='opacity'
        begin='-0.08223684210526316s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
    <rect width='7' height='13' x='46.5' y='26.5' rx='2.73' ry='2.73' transform='rotate(315 50 50)'>
      <animate
        attributeName='opacity'
        begin='0s'
        dur='0.6578947368421053s'
        keyTimes='0;1'
        repeatCount='indefinite'
        values='1;0'
      />
    </rect>
  </>
)

export const LoaderStatic = createIcon(
  40,
  40,
  <>
    <path
      d='M20 5C17.0333 5 14.1332 5.87973 11.6665 7.52796C9.19972 9.17618 7.27713 11.5189 6.14181 14.2597C5.0065 17.0006 4.70945 20.0166 5.28823 22.9264C5.86701 25.8361 7.29562 28.5088 9.3934 30.6066C11.4912 32.7044 14.1639 34.133 17.0737 34.7118C19.9834 35.2906 22.9994 34.9935 25.7403 33.8582C28.4811 32.7229 30.8238 30.8003 32.4721 28.3336C34.1203 25.8668 35 22.9667 35 20'
      stroke='#E43C4A'
      strokeWidth='5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>,
  {
    fill: 'none',
  }
)
