import * as carriage from './carriage'
import * as core from './core'
import * as counter from './counter'
import * as loader from './loader'
import * as stepper from './stepper'

export const icons = {
  core,
  counter,
  carriage,
  loader,
  stepper,
}
