import { createSlice } from '@reduxjs/toolkit'
import { Booking } from 'interfaces/Booking'
import { bookingHistoryAction } from 'store/features/bookingHistory/actions'

export interface BookingHistoryState {
  entities: Array<Booking>
  isLoading: boolean
  isError: boolean
}

export const initialState: BookingHistoryState = {
  entities: [],
  isLoading: true,
  isError: false,
}

export const bookingHistorySlice = createSlice({
  name: 'bookingHistory',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(bookingHistoryAction.pending, state => ({
        ...state,
        isLoading: true,
        isError: false,
      }))
      .addCase(bookingHistoryAction.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        isError: false,
        entities: action.payload,
      }))
      .addCase(bookingHistoryAction.rejected, () => ({
        isLoading: false,
        isError: true,
        entities: [],
      }))
  },
})

export const { reducer } = bookingHistorySlice
