import { createSlice } from '@reduxjs/toolkit'

interface Authorization {
  phone: string
  isAuthenticated: boolean
}

export const initialState: Authorization = {
  phone: '',
  isAuthenticated: false,
}

export const authSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {},
})

export const { reducer } = authSlice
