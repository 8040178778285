import { FC } from 'react'
import { palette } from 'styles/theme/palette'

import { Props } from './props'
import { Wrapper } from './styles'

export const Paper: FC<Props> = ({
  children,
  shadow = true,
  background = palette.Core.WHITE,
  radius = 8,
  ...props
}) => (
  <Wrapper {...props} shadow={shadow} background={background} radius={radius}>
    {children}
  </Wrapper>
)
