import { createSelector } from '@reduxjs/toolkit'
import { Ticket } from 'interfaces/Ticket'
import { RootState } from 'store/core'

const getState = (state: RootState) => state.ticketsSearch

const isSeatsAvailable = ({ platzkartMinimalPrice, coupeMinimalPrice }: Ticket) =>
  platzkartMinimalPrice > 0 || coupeMinimalPrice > 0

const isSameCarrier = (outbound: Ticket, inbound: Ticket) =>
  outbound.serviceType.code === inbound.serviceType.code

export const getFilteredTickets = createSelector(getState, ({ entities }) => {
  const filteredTickets = entities.filter(ticket => {
    const outboundAvailable = isSeatsAvailable(ticket.outbound)
    if (!ticket.inbound) return outboundAvailable
    const inboundAvailable = isSeatsAvailable(ticket.inbound)
    const sameCarrier = isSameCarrier(ticket.outbound, ticket.inbound)
    return outboundAvailable && inboundAvailable && sameCarrier
  })

  return {
    filteredTickets,
    isSeatsAvailable: Boolean(filteredTickets.length),
    isTicketsAvailable: Boolean(entities.length),
  }
})

export const getSearchValuesState = createSelector(getState, ({ searchValues }) => searchValues)

export const getIsSingleRide = createSelector(getState, ({ searchValues }) => searchValues.isSingle)
