import { CarriageSeat } from 'interfaces/Carriage'
import { SeatPropertyCode, TariffCode } from 'interfaces/Codes'
import { Ticket } from 'interfaces/Ticket'

const getTariffCode = (seat: CarriageSeat) => {
  const { inventoryClass, propertyCodes } = seat

  // COUPE CASE
  if (inventoryClass === '2K')
    return propertyCodes.includes(SeatPropertyCode.STANDARD)
      ? TariffCode.COUPE_2K02_STANDARD
      : TariffCode.COUPE_2K03_PREMIUM

  // PLATZKART CASE
  if (inventoryClass === '3P')
    return propertyCodes.includes(SeatPropertyCode.STANDARD)
      ? TariffCode.PLATZKART_3P05_STAN
      : TariffCode.PLATZKART_3P06_PREM

  return TariffCode.PLATZKART_3P05_STAN
}

export const getSeatInventoryClass = (seat: CarriageSeat, ticket: Ticket) => {
  const inventoryClasses = ticket.inventoryClasses[seat.inventoryClass]
  if (!inventoryClasses || inventoryClasses.length === 0) return null
  if (inventoryClasses.length === 1) return inventoryClasses[0]

  const tariffCode = getTariffCode(seat)

  return (
    inventoryClasses.find(inventoryClass =>
      inventoryClass.items.find(item =>
        item.passengerFares.find(fare => fare.tariffCode === tariffCode)
      )
    ) || inventoryClasses[0]
  )
}
