import { Container } from 'components/ui/Container'
import { useHeaderMobileMenu } from 'hooks/useHeaderMobileMenu'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'

import { DesktopHeader } from './libs/DesktopHeader'
import { MobileHeader } from './libs/MobileHeader'
import { MobileMenu } from './libs/MobileMenu'
import { Wrapper } from './styles'

export const Header: FC = () => {
  const router = useRouter()
  const { t } = useTranslation('header')
  const { open, close, isOpen } = useHeaderMobileMenu()

  const transparentPaths = [
    '/',
    '/payment/success',
    '/payment/[gateway]/success',
    '/otp/[type]',
    '/booking/old-history',
    '/booking/old-history/[bookingId]/print',
  ]
  const isTransparent = transparentPaths.includes(router.pathname)

  const links = [
    {
      url: '/',
      text: t('buyTicket'),
    },
    {
      url: '',
      text: t('myTickets'),
      childLinks: [
        {
          url: '/?refund',
          text: t('returnTicket'),
        },
        {
          url: '/?print',
          text: t('printTicket'),
        },
        {
          url: '/?print',
          text: t('printBoardingPass'),
        },
      ],
    },
    {
      url: '/faq',
      text: t('faq'),
    },
    {
      url: '/covid',
      text: t('covid'),
    },
    {
      url: '/kontakty',
      text: t('contacts'),
    },
  ]

  return (
    <Wrapper isTransparent={isTransparent} className='header-wrapper'>
      <Container>
        <DesktopHeader links={links} />
        <MobileHeader open={open} />
        <MobileMenu links={links} close={close} isOpen={isOpen} />
      </Container>
    </Wrapper>
  )
}
