import styled from 'styled-components'

interface Props {
  isActive?: boolean
}

export const Wrapper = styled.div<Props>`
  a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;
    font-weight: bold;

    &:hover {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 1180px) {
    position: relative;

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      top: 50%;
      left: -22px;
      opacity: 0;
      transition: opacity 0.2s;
      transform: translateY(-50%);
      background-color: #e43c4a;
    }

    ${({ isActive }) =>
      isActive &&
      `
      &:before {
        opacity: 1;
      }
    `}

    span {
      font-size: 16px;
    }

    a {
      font-weight: bold;
    }
  }
`
