import { BookingPassenger } from 'interfaces/Booking'
import { Passenger, PassengerTypes } from 'interfaces/Passenger'

export const getPassengersCount = (passengers: Array<Passenger | BookingPassenger>) => {
  const passengersMap = passengers.reduce(
    (acc, currVal) => {
      acc[currVal.type] += 1
      return acc
    },
    {
      [PassengerTypes.BASIC_MALE]: 0,
      [PassengerTypes.BASIC_FEMALE]: 0,
      [PassengerTypes.KID]: 0,
      [PassengerTypes.KID_BABY]: 0,
      [PassengerTypes.DISABLED_MALE]: 0,
      [PassengerTypes.DISABLED_FEMALE]: 0,
    }
  )

  return {
    adultPassengers:
      passengersMap[PassengerTypes.BASIC_MALE] + passengersMap[PassengerTypes.BASIC_FEMALE],
    kidPassengers: passengersMap[PassengerTypes.KID] + passengersMap[PassengerTypes.KID_BABY],
    preferentialPassengers:
      passengersMap[PassengerTypes.DISABLED_MALE] + passengersMap[PassengerTypes.DISABLED_FEMALE],
    totalPassengers: passengers.length,
  }
}
