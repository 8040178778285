import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { AxiosResponse } from 'axios'
import { SERVICE_TOKEN } from 'core/env'
import { Kaspi, Paybox, PaymentGateway } from 'interfaces/Payment'

export const payBooking = async (bookingId: string, gateway: PaymentGateway) => {
  const { data } = await baseAxios.post<Record<string, never>, AxiosResponse<Paybox | Kaspi>>(
    URLS.payBooking(gateway, bookingId),
    {},
    {
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
      },
    }
  )
  return data
}
