import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  BookingDocument,
  BookingPassengersRequestData,
  FormikBookingPassenger,
} from 'interfaces/Booking'
import { Phone } from 'interfaces/PassengerData'
import { format } from 'utilities/format'
import { removeSpaces } from 'utilities/removeSpaces'

export interface BookingUpdateState {
  update: BookingPassengersRequestData | null
  isRaw: boolean
}

export interface BookingUpdateRawState {
  email: string
  phone: Phone
  sendTicketBySms: boolean
  passengers: Array<FormikBookingPassenger>
}

const formatDate = (birthDate: string) => {
  const [day, month, year] = birthDate.split('.').map(item => Number(item))
  return format(new Date(year, month - 1, +day), 'yyyy-MM-dd', 'ru')
}

const formatRawBookingUpdate = (raw: BookingUpdateRawState) => ({
  phoneNumber: `+${raw.phone.value}`,
  email: raw.email,
  smsNotification: Boolean(raw.sendTicketBySms),
  passengers: raw.passengers.map(passenger => ({
    type: passenger.type,
    email: passenger.email,
    sqillsId: passenger.sqillsId,
    phoneNumber: passenger.phoneNumber,
    refId: passenger.refId,
    sqillsUuid: passenger.sqillsUuid,
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    gender: passenger.gender,
    birthDate: formatDate(passenger.birthDate),
    patronymic: passenger.patronymic,
    travelDocument: {
      docType: passenger.document,
      documentNumber: removeSpaces(passenger.documentNumber),
      personalIdentificationNumber: passenger.identityNumber,
      countryOfIssue: passenger.document === BookingDocument.ForeignDocument ? 'RU' : '',
      expiryDate: '',
    },
  })),
})

export const initialState: BookingUpdateState = {
  update: null,
  isRaw: false,
}

export const bookingUpdateSlice = createSlice({
  name: 'bookingUpdate',
  initialState,
  reducers: {
    setBookingUpdate: (state, action: PayloadAction<BookingPassengersRequestData | null>) => ({
      ...state,
      isRaw: false,
      update: action.payload,
    }),

    setRawBookingUpdate: (state, action: PayloadAction<BookingUpdateRawState>) => ({
      ...state,
      isRaw: true,
      update: formatRawBookingUpdate(action.payload),
    }),
  },
})

export const { setBookingUpdate, setRawBookingUpdate } = bookingUpdateSlice.actions

export const { reducer } = bookingUpdateSlice
