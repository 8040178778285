import { createSlice } from '@reduxjs/toolkit'
import { DirectionTicketsData } from 'components/pages/new/BookingRefund/types'
import { getRefundDataAction } from 'store/features/refund/actions'

export interface RefundState {
  ticketsData: Array<DirectionTicketsData>
  isLoading: boolean
  isError: boolean
}

export const initialState: RefundState = {
  ticketsData: [],
  isLoading: true,
  isError: false,
}

export const refundSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRefundDataAction.pending, state => ({
        ...state,
        isLoading: true,
        isError: false,
      }))
      .addCase(getRefundDataAction.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        isError: false,
        ticketsData: action.payload,
      }))
      .addCase(getRefundDataAction.rejected, state => ({
        ...state,
        isLoading: false,
        isError: true,
        ticketsData: [],
      }))
  },
})

export const { reducer } = refundSlice
