import { createAsyncThunk } from '@reduxjs/toolkit'
import { searchTickets } from 'api/requests/searchTickets'
import { SearchValues } from 'interfaces/SearchValues'
import { MultiTicket, TicketsList } from 'interfaces/Ticket'

interface Params extends Omit<SearchValues, 'origin' | 'destination'> {
  origin: string
  destination: string
}

const buildMultiTickets = ({ inbound, outbound }: TicketsList): Array<MultiTicket> => {
  if (!inbound.length)
    return outbound.map(outboundTicket => ({ outbound: outboundTicket, inbound: null }))

  return outbound.reduce<Array<MultiTicket>>((acc, outboundTicket) => {
    const multi = inbound.map(inboundTicket => ({
      inbound: inboundTicket,
      outbound: outboundTicket,
    }))
    return [...acc, ...multi]
  }, [])
}

export const fetchTicketsSearch = createAsyncThunk('fetchTicketsSearch', async (params: Params) => {
  const response = await searchTickets({
    ...params,
    outboundDate: new Date(params.outboundDate || ''),
    inboundDate: params.inboundDate ? new Date(params.inboundDate) : undefined,
  })

  return buildMultiTickets(response)
})
