import { FC } from 'react'

import { Container, Props as ContainerProps } from './libs/Container'
import { Item, Props as ItemProps } from './libs/Item'

type ContainerItemParams = ContainerProps & ItemProps

interface Props extends ContainerItemParams {
  container?: boolean
  item?: boolean
}

export const Flex: FC<Props> = ({ children, container = false, item = false, ...props }) => {
  if (container) return <Container {...props}>{children}</Container>
  if (item) return <Item {...props}>{children}</Item>
  return <div>Specify a flex type: &ldquo;container&rdquo; or &ldquo;item&rdquo;</div>
}
