import baseAxios from 'api/axios/baseAxios'
import extendAxiosInstance from 'api/axios/extendAxiosInstance'
import { refreshToken } from 'api/requests/refreshToken'
import { TokenStorage } from 'auth/TokenStorage'
import { AxiosError } from 'axios'
import { SERVICE_TOKEN } from 'core/env'

const authAxios = extendAxiosInstance(baseAxios, {})

const tokenStorage = TokenStorage.getInstance()

authAxios.interceptors.request.use(
  config => {
    const headers = { ...config.headers }
    const authToken = tokenStorage.getAccessToken()

    if (!headers.Authorization && authToken) {
      headers.Authorization = `Bearer ${authToken}`
    }

    if (!headers.Authorization) {
      delete headers.Authorization
    }

    if (!headers['Authorization-Service']) {
      headers['Authorization-Service'] = `Bearer ${SERVICE_TOKEN}`
    }

    return {
      ...config,
      headers,
    }
  },
  error => Promise.reject(error)
)

authAxios.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      try {
        const refresh = tokenStorage.getRefreshToken()
        const { token, refreshKey } = await refreshToken(refresh)
        tokenStorage.setTokens(token, refreshKey)

        const requestConfig = {
          ...error.config,
          headers: {
            ...(error.config.headers || {}),
            Authorization: `Bearer ${token}`,
          },
        }
        return await authAxios.request(requestConfig)
      } catch (e) {
        // In this case upper scope will receive message from first request eg. `Unauthenticated.`
      }
    }

    throw error
  }
)

export default authAxios
