import { CarriageLayoutType } from 'interfaces/Carriage'
import { ProductFamilyId } from 'interfaces/Codes'
import { InventoryClass, InventoryClassCode } from 'interfaces/Ticket'

const familyIdByInventoryClass: Record<CarriageLayoutType, Array<ProductFamilyId>> = {
  coupe: [
    ProductFamilyId.TICKET_CP_PREM,
    ProductFamilyId.TICKET_CP_DSC,
    ProductFamilyId.TICKET_CP_STAND,
    ProductFamilyId.COUPE_PRIVATE,
  ],
  platzkart: [
    ProductFamilyId.TICKET_PL_PREM,
    ProductFamilyId.TICKET_PL_DSC,
    ProductFamilyId.TICKET_PL_STAND,
    ProductFamilyId.PLATZKART_PRIVATE,
  ],
}

export const getCheapestInventoryClass = (
  inventoryClasses: Partial<Record<InventoryClassCode, InventoryClass[]>>,
  filter?: CarriageLayoutType | null
) => {
  const inventoryClassesList = Object.values(inventoryClasses).reduce(
    (acc, inventoryClassesArr) => [...acc, ...inventoryClassesArr],
    []
  )

  return inventoryClassesList.reduce((acc, inventoryClass) => {
    if (acc.price < inventoryClass.price) return acc
    if (!filter) return inventoryClass

    if (familyIdByInventoryClass[filter].includes(inventoryClass.productFamilyId))
      return inventoryClass
    return acc
  }, {} as InventoryClass)
}
