import styled, { css } from 'styled-components'
import { pixelize } from 'utilities/pixelize'

import { Props, Radius } from './props'

const getRadius = (radius: number | Radius) => {
  if (typeof radius === 'number') {
    return css`
      border-radius: ${pixelize(radius)};
    `
  }

  return css`
    ${radius.topLeft && `border-top-left-radius: ${pixelize(radius.topLeft)};`}
    ${radius.topRight && `border-top-right-radius: ${pixelize(radius.topRight)};`}
    ${radius.bottomleft && `border-bottom-left-radius: ${pixelize(radius.bottomleft)};`}
    ${radius.bottomRight && `border-bottom-right-radius: ${pixelize(radius.bottomRight)};`}
  `
}

export const Wrapper = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: block;
  background-color: ${({ background }) => background};
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.shadow.primary.MAIN : 'none')};
  ${({ radius }) => radius && getRadius(radius)}
`
