import { Box } from 'components/ui/Box'
import { Container } from 'components/ui/Container'
import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import { Typography } from 'components/ui/Typography'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import {
  FlexContainer,
  FooterLinkWrapper,
  LogoBox,
  Navbar,
  NavbarFlexContainer,
  NavbarItem,
  Payment,
  PaymentItem,
  SocialNetworkIcons,
  SocialNetworkIconsBox,
  Wrapper,
} from './styles'

export const Footer: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation('footer')

  const links = [
    {
      label: t('info.label'),
      links: [
        {
          label: t('info.publicOffer'),
          url: '/publichnaya-oferta',
        },
        {
          label: t('info.privacyPolicy'),
          url: '/politika-konfidencialnosti',
        },
        {
          label: t('info.covid'),
          url: '/covid',
        },
        {
          label: t('info.payment'),
          url: '/payment',
        },
      ],
    },
    {
      label: t('tickets.label'),
      links: [
        {
          label: t('tickets.faq'),
          url: '/faq',
        },
        {
          label: t('tickets.returnTicket'),
          url: '/?refund',
        },
        {
          label: t('tickets.myTicket'),
          url: '/?print',
        },
        {
          label: t('tickets.myBoardingPass'),
          url: '/?print',
        },
      ],
    },
    {
      label: t('contacts.label'),
      links: [
        {
          label: t('contacts.contacts'),
          url: '/kontakty',
        },
        {
          label: t('contacts.support'),
          url: 'https://support.bile-bile.kz/',
          external: true,
        },
      ],
    },
  ]

  return (
    <Wrapper>
      <Container>
        <FlexContainer container justify='space-between' alignItems='flex-start' columnGap={60}>
          <SocialNetworkIcons item shrink={0} grow={0}>
            <SocialNetworkIconsBox>
              <Link href='/'>
                <a>
                  <LogoBox marginBottom={51}>
                    <Icon icon={theme.icons.core.Logo} color={theme.palette.secondary.MAIN} />
                  </LogoBox>
                </a>
              </Link>
            </SocialNetworkIconsBox>
          </SocialNetworkIcons>
          <NavbarFlexContainer item grow={1}>
            <Navbar container justify='flex-start' columnGap={30}>
              {links.map(coreLink => (
                <NavbarItem key={coreLink.label}>
                  <Typography
                    typography={theme.typography.variants.content.BOLD}
                    color={theme.palette.primary.MAIN}
                  >
                    {coreLink.label}
                  </Typography>
                  {coreLink.links.map(link => (
                    <Box key={link.label} marginTop={16}>
                      {'url' in link && (
                        <FooterLinkWrapper>
                          <Typography
                            color={theme.palette.text.MAIN}
                            typography={theme.typography.variants.content.MAIN}
                          >
                            {link.external ? (
                              <a href={link.url} target='_blank' rel='noopener noreferrer'>
                                {link.label}
                              </a>
                            ) : (
                              <Link href={link.url}>
                                <a>{link.label}</a>
                              </Link>
                            )}
                          </Typography>
                        </FooterLinkWrapper>
                      )}
                    </Box>
                  ))}
                </NavbarItem>
              ))}
              <PaymentItem>
                <Typography
                  typography={theme.typography.variants.content.BOLD}
                  color={theme.palette.primary.MAIN}
                >
                  {t('payments')}
                </Typography>
                <Box marginTop={18}>
                  <Flex container columnGap={8} alignItems='center'>
                    <Payment>
                      <Icon icon={theme.icons.core.Mastercard} />
                    </Payment>
                    <Payment>
                      <Icon icon={theme.icons.core.Visa} />
                    </Payment>
                    <Payment>
                      <Icon icon={theme.icons.core.Kaspi} />
                    </Payment>
                  </Flex>
                </Box>
              </PaymentItem>
            </Navbar>
          </NavbarFlexContainer>
        </FlexContainer>
      </Container>
    </Wrapper>
  )
}
