import { createIcon } from 'styles/theme/icons/createIcon'

export const SeatArrowTop = createIcon(
  7,
  4,
  'M0.417364 2.90912C0.163146 3.091 0.104504 3.44453 0.286384 3.69874C0.468264 3.95296 0.821791 4.0116 1.07601 3.82972L3.20062 2.30968L5.32523 3.82972C5.57945 4.0116 5.93298 3.95296 6.11486 3.69874C6.29674 3.44453 6.23809 3.091 5.98388 2.90912L3.20198 0.918821V0.91687L3.20062 0.917845L0.417364 2.90912Z'
)

export const SeatArrowBottom = createIcon(
  7,
  4,
  'M0.5492 1.41339C0.294982 1.23151 0.23634 0.877985 0.41822 0.623766C0.6001 0.369548 0.953627 0.310905 1.20785 0.492785L3.33246 2.01283L5.45707 0.492785C5.71128 0.310905 6.06481 0.369548 6.24669 0.623766C6.42857 0.877985 6.36993 1.23151 6.11571 1.41339L3.33382 3.40369V3.40564L3.33246 3.40466L0.5492 1.41339Z'
)

export const WC = createIcon(
  32,
  32,
  <>
    <path
      d='M8.33301 19.3334V18.3334H7.33301H6.66634C6.48529 18.3334 6.33301 18.1811 6.33301 18.0001V12.0001C6.33301 11.0857 7.08529 10.3334 7.99967 10.3334H11.9997C12.9141 10.3334 13.6663 11.0857 13.6663 12.0001V18.0001C13.6663 18.1811 13.5141 18.3334 13.333 18.3334H12.6663H11.6663V19.3334V28.0001C11.6663 28.1811 11.5141 28.3334 11.333 28.3334H8.66634C8.48529 28.3334 8.33301 28.1811 8.33301 28.0001V19.3334ZM23.9997 20.3334H22.9997V21.3334V28.0001C22.9997 28.1811 22.8474 28.3334 22.6663 28.3334H21.333C21.152 28.3334 20.9997 28.1811 20.9997 28.0001V21.3334V20.3334H19.9997H17.853C17.6155 20.3334 17.4603 20.1104 17.5336 19.894L17.5352 19.8892L20.3352 11.4759L20.3362 11.4728C20.5596 10.7942 21.2 10.3334 21.9197 10.3334H22.0797C22.7904 10.3334 23.4273 10.7865 23.6655 11.48L26.4624 19.8837C26.5356 20.1166 26.3598 20.3334 26.1463 20.3334H23.9997ZM11.6663 5.33341C11.6663 6.26113 10.9274 7.00008 9.99967 7.00008C9.07196 7.00008 8.33301 6.26113 8.33301 5.33341C8.33301 4.4057 9.07196 3.66675 9.99967 3.66675C10.9274 3.66675 11.6663 4.4057 11.6663 5.33341ZM23.6663 5.33341C23.6663 6.26113 22.9274 7.00008 21.9997 7.00008C21.072 7.00008 20.333 6.26113 20.333 5.33341C20.333 4.4057 21.072 3.66675 21.9997 3.66675C22.9274 3.66675 23.6663 4.4057 23.6663 5.33341Z'
      stroke='#BFBFBF'
      strokeWidth='2'
    />
  </>,
  { fill: 'none' }
)

export const Cafe = createIcon(
  32,
  32,
  <>
    <path
      d='M22 17H3C3 21 4.312 23.537 7 24.921V27H18V24.921C20.688 23.537 22 21 22 17Z'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.371 16.929L20 11H29L28 27H21L20.723 22.576'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.5 15H28.5'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15 13V12'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.9995 9C14.9995 7.895 15.8945 7 16.9995 7H18.9995C20.1045 7 20.9995 6.105 20.9995 5M7.87752 16.999L3.43852 12.56C2.85252 11.974 2.85252 11.024 3.43852 10.439C4.02452 9.853 4.97452 9.853 5.55952 10.439L12.1175 16.997'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.5 20H21.5'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>,
  { fill: 'none' }
)

export const AirCon = createIcon(
  32,
  32,
  <>
    <path
      d='M27.9993 5.3335H3.99935C3.26297 5.3335 2.66602 5.93045 2.66602 6.66683V17.3335C2.66602 18.0699 3.26297 18.6668 3.99935 18.6668H27.9993C28.7357 18.6668 29.3327 18.0699 29.3327 17.3335V6.66683C29.3327 5.93045 28.7357 5.3335 27.9993 5.3335Z'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.3324 9.3335H23.999M15.999 22.6668V26.6668M10.6657 24.0002V25.3335M21.3324 24.0002V25.3335M7.99902 13.3335H23.999V18.6668H7.99902V13.3335Z'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>,
  { fill: 'none' }
)

export const BioToilet = createIcon(
  32,
  32,
  <>
    <path
      d='M12 13.3333C12 15.8087 11.5786 18.1827 10.8284 19.933C10.0783 21.6833 9.06087 22.6667 8 22.6667C6.93913 22.6667 5.92172 21.6833 5.17157 19.933C4.42143 18.1827 4 15.8087 4 13.3333C4 10.858 4.42143 8.48401 5.17157 6.73367C5.92172 4.98333 6.93913 4 8 4M12 13.3333C12 10.858 11.5786 8.48401 10.8284 6.73367C10.0783 4.98333 9.06087 4 8 4M12 13.3333V26.6667L16 24L20 28L24 25.3333L28 26.6667V13.3333C28 8.17867 26.2093 4 24 4H8M8 13.3333H8.01333'
      stroke='#BFBFBF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>,
  { fill: 'none' }
)

export const Pet = createIcon(
  32,
  32,
  <>
    <path
      d='M19.0403 13.44C21.1203 13.44 22.4003 10.4 22.4003 8.48005C22.4003 6.88005 21.6003 4.96005 20.0003 4.96005C17.9203 4.96005 16.6403 8.00005 16.6403 9.92005C16.6403 11.52 17.4403 13.44 19.0403 13.44ZM12.9603 13.44C14.5603 13.44 15.3603 11.52 15.3603 9.92005C15.3603 7.84005 14.0803 4.80005 12.0003 4.80005C10.4003 4.80005 9.60027 6.72005 9.60027 8.32005C9.44027 10.4 10.7203 13.44 12.9603 13.44ZM24.8003 11.84C22.7203 11.84 21.2803 14.72 21.2803 16.8C21.2803 18.24 21.9203 19.68 23.3603 19.68C25.4403 19.68 26.8803 16.8 26.8803 14.72C26.8803 13.28 26.0803 11.84 24.8003 11.84ZM10.8803 16.8C10.8803 14.72 9.28027 11.84 7.36027 11.84C5.92027 11.84 5.28027 13.28 5.28027 14.72C5.28027 16.8 6.88027 19.68 8.80027 19.68C10.2403 19.68 10.8803 18.24 10.8803 16.8ZM16.0003 16.48C12.8003 16.48 8.48027 21.6 8.48027 25.12C8.48027 26.72 9.60027 27.2 10.8803 27.2C12.8003 27.2 14.2403 25.9201 16.0003 25.9201C17.6003 25.9201 19.0403 27.2 20.8003 27.2C22.0803 27.2 23.5203 26.88 23.5203 25.12C23.5203 21.6 19.2003 16.48 16.0003 16.48Z'
      stroke='#BFBFBF'
      strokeWidth='2'
    />
  </>,
  { fill: 'none' }
)

export const CafeCar = createIcon(
  32,
  32,
  <>
    <path
      d='M28.0003 19.9999C28.0003 13.8333 23.3243 8.74525 17.3337 8.07859V7.66659C17.3337 6.93021 16.7367 6.33325 16.0003 6.33325C15.2639 6.33325 14.667 6.93021 14.667 7.66659V8.07859C8.67632 8.74525 4.00033 13.8333 4.00033 19.9999V22.6666H28.0003V19.9999ZM6.66699 19.9999C6.66699 14.8546 10.855 10.6666 16.0003 10.6666C21.1457 10.6666 25.3337 14.8546 25.3337 19.9999H6.66699ZM0.666992 25.3333C0.666992 24.5969 1.26395 23.9999 2.00032 23.9999H30.0003C30.7367 23.9999 31.3337 24.5969 31.3337 25.3333C31.3337 26.0696 30.7367 26.6666 30.0003 26.6666H2.00032C1.26394 26.6666 0.666992 26.0696 0.666992 25.3333Z'
      fill='#BFBFBF'
    />
  </>,
  { fill: 'none' }
)

export const Female = createIcon(
  38,
  32,
  <>
    <g clipPath='url(#clip0_4835_26733)'>
      <path
        d='M18.8575 20.8999V25.4499C18.8559 25.5882 18.8825 25.7255 18.9358 25.8536C18.989 25.9817 19.0679 26.0981 19.1677 26.1959C19.2675 26.2937 19.3863 26.371 19.517 26.4232C19.6477 26.4754 19.7878 26.5015 19.9289 26.4999C20.0701 26.5015 20.2101 26.4754 20.3408 26.4232C20.4716 26.371 20.5903 26.2937 20.6901 26.1959C20.7899 26.0981 20.8688 25.9817 20.9221 25.8536C20.9754 25.7255 21.002 25.5882 21.0003 25.4499'
        stroke='#BFBFBF'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M20.9991 20.9001V25.4501C20.9975 25.5884 21.0241 25.7257 21.0774 25.8538C21.1307 25.9819 21.2095 26.0983 21.3093 26.1961C21.4091 26.2939 21.5279 26.3712 21.6586 26.4234C21.7893 26.4756 21.9294 26.5017 22.0705 26.5001C22.2117 26.5017 22.3517 26.4756 22.4825 26.4234C22.6132 26.3712 22.7319 26.2939 22.8318 26.1961C22.9316 26.0983 23.0104 25.9819 23.0637 25.8538C23.117 25.7257 23.1436 25.5884 23.142 25.4501V20.9001M17.7402 16.7876C17.7016 16.9207 17.6362 17.0449 17.5479 17.1528C17.4597 17.2608 17.3503 17.3504 17.2262 17.4163C17.102 17.4823 16.9657 17.5233 16.8253 17.5368C16.6848 17.5504 16.5429 17.5364 16.408 17.4955C16.2723 17.4576 16.1456 17.3936 16.0354 17.3071C15.9252 17.2206 15.8338 17.1133 15.7666 16.9917C15.6993 16.8701 15.6576 16.7365 15.6438 16.5988C15.6299 16.4611 15.6444 16.3221 15.6862 16.19L16.8022 12.5027C16.9253 12.0974 17.1787 11.7418 17.5247 11.4891C17.8707 11.2363 18.2908 11.0999 18.7223 11.1001H23.2759C23.7075 11.1 24.1275 11.2366 24.4735 11.4896C24.8194 11.7425 25.0727 12.0982 25.1955 12.5036L26.3116 16.1908C26.3534 16.323 26.3678 16.462 26.354 16.5997C26.3402 16.7374 26.2985 16.8709 26.2312 16.9926C26.1639 17.1142 26.0725 17.2214 25.9624 17.3079C25.8522 17.3945 25.7255 17.4585 25.5897 17.4963C25.455 17.5368 25.3133 17.5505 25.1731 17.5367C25.0329 17.5229 24.8969 17.4817 24.773 17.4157C24.6492 17.3497 24.5401 17.2602 24.4521 17.1523C24.364 17.0445 24.2988 16.9205 24.2603 16.7876'
        stroke='#BFBFBF'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M20.9996 9C21.9858 9 22.7853 8.2165 22.7853 7.25C22.7853 6.2835 21.9858 5.5 20.9996 5.5C20.0134 5.5 19.2139 6.2835 19.2139 7.25C19.2139 8.2165 20.0134 9 20.9996 9Z'
        stroke='#BFBFBF'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M18.8567 13.2L16.7139 20.2H25.2853L23.1424 13.2'
        stroke='#BFBFBF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect x='6' y='3' width='30' height='26' rx='2.73333' stroke='#BFBFBF' strokeWidth='2' />
    </g>
  </>,
  { fill: 'none' }
)

export const WiFi = createIcon(
  32,
  32,
  <>
    <path
      d='M2.7471 13.4134C3.4271 14.0934 4.5071 14.16 5.24044 13.5467C11.4671 8.42669 20.5071 8.42669 26.7471 13.5334C27.4938 14.1467 28.5871 14.0934 29.2671 13.4134C30.0538 12.6267 30.0004 11.32 29.1338 10.6134C21.5204 4.38669 10.5071 4.38669 2.88044 10.6134C2.01377 11.3067 1.9471 12.6134 2.7471 13.4134ZM13.0938 23.76L15.0538 25.72C15.5738 26.24 16.4138 26.24 16.9338 25.72L18.8938 23.76C19.5204 23.1334 19.3871 22.0534 18.5871 21.64C17.7806 21.2259 16.887 21.0099 15.9804 21.0099C15.0738 21.0099 14.1803 21.2259 13.3738 21.64C12.6138 22.0534 12.4671 23.1334 13.0938 23.76ZM8.12044 18.7867C8.77377 19.44 9.80044 19.5067 10.5604 18.96C12.1512 17.8346 14.0518 17.2302 16.0004 17.2302C17.9491 17.2302 19.8497 17.8346 21.4404 18.96C22.2004 19.4934 23.2271 19.44 23.8804 18.7867L23.8938 18.7734C24.6938 17.9734 24.6404 16.6134 23.7204 15.96C19.1338 12.64 12.8804 12.64 8.28044 15.96C7.36044 16.6267 7.3071 17.9734 8.12044 18.7867Z'
      stroke='#BFBFBF'
      strokeWidth='2'
    />
  </>,
  { fill: 'none' }
)
