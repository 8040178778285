export enum PaymentGateway {
  Kaspi = 'kaspipay',
  Paybox = 'paybox',
}

export interface Paybox {
  form: string
}

export interface Body {
  amount: string
  orderId: string
  service: string
  signature: string
  tranId: string
  returnUrl: string
}

export interface Kaspi {
  form: string
  method: string
  body: Body
}
