import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { AxiosResponse } from 'axios'
import { SERVICE_TOKEN } from 'core/env'
import { BookingPassengersRequestData, BookingUpdateResponse } from 'interfaces/Booking'

const formatPhoneNumber = (rawPhoneNumber: string) => rawPhoneNumber.slice(2).split(' ').join('')

const payloadToSnakeCase = (payload: BookingPassengersRequestData) => ({
  phone_number: formatPhoneNumber(payload.phoneNumber),
  email: payload.email,
  sms_notification: payload.smsNotification,
  offer_accepted: true,
  passengers: payload.passengers.map(passenger => ({
    uuid: passenger.sqillsUuid,
    first_name: passenger.firstName,
    last_name: passenger.lastName,
    gender: passenger.gender === 'male' ? 'M' : 'F',
    birth_date: passenger.birthDate,
    travel_document: {
      country: passenger.travelDocument.countryOfIssue,
      type: Number(passenger.travelDocument.docType),
      document_number: passenger.travelDocument.documentNumber,
      personal_identification_number: passenger.travelDocument.personalIdentificationNumber,
    },
  })),
})

export const updateBooking = async (payload: BookingPassengersRequestData, bookingId: string) => {
  const { data } = await baseAxios.put<
    ReturnType<typeof payloadToSnakeCase>,
    AxiosResponse<BookingUpdateResponse>
  >(URLS.updateBooking(bookingId), payloadToSnakeCase(payload), {
    headers: {
      'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
    },
  })
  return data
}
