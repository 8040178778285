import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { rootReducer } from 'store/rootReducer'

const localStore = configureStore({
  reducer: rootReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof localStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof localStore.dispatch

export const createStore = (preloadedState: Partial<RootState>, useLogger = true) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => {
      const middlewares = getDefaultMiddleware()
      if (useLogger && process.env.NODE_ENV === 'development') middlewares.push(logger)
      return middlewares
    },
  })
}
