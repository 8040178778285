import { lighten, transparentize } from 'polished'
import styled from 'styled-components'
import { pixelize } from 'utilities/pixelize'

export const Wrapper = styled.div`
  width: 35px;
  .language-select {
    &__control {
      font-family: 'Open Sans', sans-serif;
      min-height: auto;
      cursor: pointer;
      background: transparent;
      border: none;
      &--is-focused,
      &--menu-is-open,
      &:hover {
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }
    &__indicator-separator {
      display: none;
    }
    &__value-container {
      padding: 0;
    }
    &__single-value {
      color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
      font-size: ${({ theme }) => pixelize(theme.typography.sizes.DEFAULT)};
      margin: 0;
      line-height: 1.4;
    }
    &__dropdown-indicator {
      margin-right: -3px;
      padding: 0;
      color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
      transition: all 0.2s ease;
      &:hover {
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
    &__menu {
      margin: 4px 0 0;
      width: 130px;
      left: -5px;
    }
    &__option {
      display: flex;
      justify-content: space-between;
      font-family: 'Open Sans', sans-serif;
      cursor: pointer;
      color: ${({ theme }) => theme.palette.text.MAIN};
      font-size: ${({ theme }) => pixelize(theme.typography.sizes.DEFAULT)};
      &--is-focused {
        background-color: ${({ theme }) => transparentize(0.85, theme.palette.secondary.MAIN)};
      }
      &--is-selected {
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
        background-color: ${({ theme }) => theme.palette.secondary.MAIN};
      }

      &:active {
        background-color: ${({ theme }) => lighten(0.1, theme.palette.secondary.MAIN)};
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
      }
    }
  }

  .selected-icon {
    display: none;
  }

  @media screen and (max-width: 1290px) {
    .language-select {
      &__menu {
        width: 100px;
      }
    }
  }

  @media screen and (max-width: 920px) {
    .language-select {
      &__menu {
        width: 80px;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    width: calc(100% + 40px);

    .language-select {
      &__control {
        &--menu-is-open {
          .language-select__dropdown-indicator {
            transform: rotate(180deg);
          }
        }
      }

      &__single-value {
        font-size: 16px;
        font-weight: bold;
      }

      &__dropdown-indicator {
        svg {
          width: 18px;
          height: 18px;
        }
      }

      &__menu {
        width: 100%;
        margin-top: 10px;
        padding-left: 5px;
        background-color: transparent;
        box-shadow: none;
      }

      &__option {
        font-size: 16px;
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;

        &--is-focused,
        &--is-selected,
        &:active {
          background-color: transparent;
        }

        &--is-selected {
          .selected-icon {
            display: block;
          }
        }
      }
    }
  }
`
