import { css } from 'styled-components'
import { isNumber } from 'utilities/isNumber'
import { pixelize } from 'utilities/pixelize'

export interface IndentDirections {
  top?: number | string
  left?: number | string
  right?: number | string
  bottom?: number | string
}

export interface IndentAxis {
  vertical?: number | string
  horizontal?: number | string
}

type IndentType = 'margin' | 'padding'

export function indent(type: IndentType, value: string | number | IndentDirections | IndentAxis) {
  if (typeof value === 'number' || typeof value === 'string')
    return css`
      ${type}: ${isNumber(value) ? pixelize(value) : value};
    `

  if ('vertical' in value || 'horizontal' in value) {
    return css`
      ${type}: ${value.vertical === 'auto'
        ? 'auto'
        : pixelize(value.vertical)} ${value.horizontal === 'auto'
        ? 'auto'
        : pixelize(value.horizontal)};
    `
  }

  if ('top' in value || 'left' in value || 'right' in value || 'bottom' in value) {
    return css`
      ${type}: ${pixelize(value.top)} ${pixelize(value.right)} ${pixelize(value.bottom)} ${pixelize(
        value.left
      )};
    `
  }

  return css``
}
