import { createAsyncThunk } from '@reduxjs/toolkit'
import { clearBookingPayment } from 'api/requests/clearBookingPayment'
import { payBooking } from 'api/requests/payBooking'
import { updateBooking } from 'api/requests/updateBooking'
import { BookingPassengersRequestData, BookingState } from 'interfaces/Booking'
import { PaymentGateway } from 'interfaces/Payment'
import { isAxiosError } from 'utilities/isAxiosError'

interface Payload {
  update?: BookingPassengersRequestData
  clearPrev?: boolean
  gateway: PaymentGateway
  bookingNumber: string
}

interface Error {
  message: string
}

export const bookingPaymentAction = createAsyncThunk(
  'bookingPayment',
  async ({ update, bookingNumber, gateway, clearPrev = false }: Payload, { rejectWithValue }) => {
    if (update) {
      const { bookingStatus } = await updateBooking(update, bookingNumber)
      if (bookingStatus === BookingState.Confirmed || bookingStatus === BookingState.Paid) {
        return {
          form: `${window.location.origin}/payment/${gateway}/success?booking=${bookingNumber}`,
        }
      }
    }

    try {
      if (clearPrev) await clearBookingPayment(bookingNumber)
      const data = await payBooking(bookingNumber, gateway)
      return data
    } catch (e) {
      if (isAxiosError<Error>(e)) {
        return rejectWithValue(e?.response?.data?.message || '')
      }

      return rejectWithValue('')
    }
  }
)
