import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import Link from 'next/link'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import { IconWrap, Wrapper } from './styles'

interface Props {
  open: () => void
}

export const MobileHeader: FC<Props> = ({ open }) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Flex container justify='space-between' alignItems='center'>
        <Link href='/'>
          <a>
            <Icon icon={theme.icons.core.Logo} color={theme.palette.core.WHITE} />
          </a>
        </Link>
        <IconWrap>
          <Icon
            data-testid='icon'
            icon={theme.icons.core.Burger}
            color={theme.palette.core.VERY_LIGHT_GRAY}
            onClick={open}
          />
        </IconWrap>
      </Flex>
    </Wrapper>
  )
}
