import { css } from 'styled-components'

export const globalStyles = () => css`
  * {
    z-index: 1;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }

  [data-rsbs-root] {
    .custom-backdrop {
      z-index: 6;
      overscroll-behavior: none;
      touch-action: none;
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      top: -60px;
      bottom: -60px;
      background: none;
      background-color: rgba(0, 0, 0, 0.6);
      will-change: opacity;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

    &[data-rsbs-state='closing'] .custom-backdrop {
      opacity: 0;
    }
  }

  [data-rsbs-overlay] {
    z-index: 7;
  }

  body {
    width: 100%;
  }

  .ReactCollapse--collapse {
    transition: height 400ms;
  }
`
