import { createSelector } from '@reduxjs/toolkit'
import { CarriageLayoutType } from 'interfaces/Carriage'
import { RootState } from 'store/core'
import { getSeatStatus } from 'store/features/passengers/utilities/getSeatStatus'

const getState = (state: RootState) => state.carriages

const getPassengersState = (state: RootState) => state.passengers

export const getAllCarriages = createSelector(getState, ({ entities }) => entities)

export const getCarriagesByType = (type: CarriageLayoutType) =>
  createSelector(getAllCarriages, carriages =>
    carriages.filter(carriage => (type === 'coupe' ? carriage.isCoupe : !carriage.isCoupe))
  )

export const getFilteredCarriages = createSelector(
  getState,
  getPassengersState,
  ({ entities: carriages }, { currentStepType, ticket: multiTicket, ...passengers }) => {
    const { filter } = passengers[currentStepType]
    const ticket = multiTicket ? multiTicket[currentStepType] : null

    return carriages
      .filter(carriage => {
        const availableSeats = carriage.seats.filter(
          seat => seat.available && getSeatStatus(ticket, seat) === 'available'
        )
        if (!availableSeats.length) return false
        if (!carriage.acceptManualAllocation) return false
        if (filter === 'coupe') return carriage.isCoupe
        if (filter === 'platzkart') return !carriage.isCoupe
        return true
      })
      .sort((c1, c2) => {
        if (c1.isCoupe && c2.isCoupe) return 0
        return c1.isCoupe ? -1 : 1
      })
  }
)
