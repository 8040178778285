import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import { PassengerTypes } from 'interfaces/Passenger'
import {
  emptyInventoryClasses,
  InventoryClass,
  InventoryClassCode,
  Ticket,
  TicketsList,
} from 'interfaces/Ticket'
import { getTimezone } from 'utilities/getTimezone'
import { toDateWithoutTZ } from 'utilities/toDateWithoutTZ'

interface Params {
  origin: string
  destination: string
  outboundDate: Date
  inboundDate?: Date
  passengers: Record<PassengerTypes, number>
}

interface RequestBody {
  origin: string
  destination: string
  outbound_date: string
  inbound_date?: string
  passengers: Record<PassengerTypes, number>
}

const filterNonUniqueInventoryClasses = (inventoryClasses: Array<InventoryClass>) => {
  const unique = new Set()
  inventoryClasses.forEach(inventoryClass => unique.add(inventoryClass.id))
  return Array.from(unique).map(id =>
    inventoryClasses.find(inventoryClass => inventoryClass.id === id)
  )
}

const filterInventoryClasses = (
  inventoryClasses: Record<InventoryClassCode, Array<InventoryClass>>
) =>
  Object.entries(inventoryClasses).reduce<Record<InventoryClassCode, Array<InventoryClass>>>(
    (acc, [code, classes]) => ({
      ...acc,
      [code]: filterNonUniqueInventoryClasses(classes),
    }),
    emptyInventoryClasses
  )

const mapTickets = ({ travelDate, departureDateTime, arrivalDateTime, ...ticket }: Ticket) => ({
  ...ticket,
  travelDate: toDateWithoutTZ(travelDate).toISOString(),
  departureDateTime: toDateWithoutTZ(departureDateTime).toISOString(),
  departureTimeZone: getTimezone(departureDateTime),
  arrivalDateTime: toDateWithoutTZ(arrivalDateTime).toISOString(),
  arrivalTimeZone: getTimezone(arrivalDateTime),
  inventoryClasses: {
    ...filterInventoryClasses(ticket.inventoryClasses),
  },
})

export const searchTickets = async ({
  origin,
  destination,
  outboundDate,
  inboundDate,
  passengers: rawPassengers,
}: Params): Promise<TicketsList> => {
  const passengers = {
    ...rawPassengers,
    // Remove all kid baby 'cause babies do not need a tickets
    [PassengerTypes.KID_BABY]: 0,
  }

  const body: RequestBody = {
    origin,
    destination,
    outbound_date: format(outboundDate, 'yyyy-MM-dd'),
    passengers,
  }

  if (inboundDate) {
    body.inbound_date = format(inboundDate, 'yyyy-MM-dd')
  }

  const { data } = await baseAxios.post<RequestBody, AxiosResponse<TicketsList>>(URLS.tickets, body)

  return {
    inbound: data.inbound?.map(mapTickets) || [],
    outbound: data.outbound?.map(mapTickets) || [],
  }
}
