import { TOKEN_KEYS } from 'auth/tokenKeys'
import { getCookie, setCookie } from 'utilities/cookie'

// TokenStorage is a singleton. https://patterns.dev/posts/singleton-pattern/
// Needed to pass a pair of auth tokens between the layers of SSR and client.
// It initializes both on client side and on SSR layer.
// On the client side it initializes in '_app.tsx' file,
// and on the SSR layer, when resources are requested

export class TokenStorage {
  private static instance: TokenStorage

  private accessToken = ''

  private refreshToken = ''

  public static getInstance(): TokenStorage {
    if (!TokenStorage.instance) {
      TokenStorage.instance = new TokenStorage()

      if ('window' in global) {
        const authToken = getCookie(TOKEN_KEYS.ACCESS_TOKEN)
        const refreshToken = getCookie(TOKEN_KEYS.REFRESH_TOKEN)

        TokenStorage.instance.setTokens(authToken, refreshToken)
      }
    }

    return TokenStorage.instance
  }

  public getRefreshToken() {
    return this.refreshToken || ''
  }

  public setRefreshToken(token: string) {
    this.refreshToken = token
  }

  public getAccessToken() {
    return this.accessToken || ''
  }

  public setAccessToken(token: string) {
    this.accessToken = token
  }

  public setTokens(access: string, refresh: string) {
    this.setAccessToken(access)
    this.setRefreshToken(refresh)

    if ('window' in global && typeof window !== 'undefined') {
      setCookie(TOKEN_KEYS.ACCESS_TOKEN, access)
      setCookie(TOKEN_KEYS.REFRESH_TOKEN, refresh)
    }
  }

  public getClientTokensFromCookie(): Record<string, string> {
    if ('document' in global) {
      const cookies = document.cookie
        .split('; ')
        .reduce<Record<string, string>>((acc, cookieStr) => {
          const [key, value] = cookieStr.split('=')
          return {
            ...acc,
            [key]: value,
          }
        }, {})
      const accessToken = cookies[TOKEN_KEYS.ACCESS_TOKEN]
      const refreshToken = cookies[TOKEN_KEYS.REFRESH_TOKEN]
      return { accessToken, refreshToken }
    }
    return {}
  }
}
