import { createIcon } from '../createIcon'

export const Arrow = createIcon(
  60,
  9,
  <>
    <rect y='4.53363' width='58.8542' height='1.02856' rx='0.514281' fill='#BDBDBD' />
    <rect
      width='0.993675'
      height='6.28525'
      rx='0.496838'
      transform='matrix(0.696454 -0.717602 0.696454 0.717602 54.9307 1.21301)'
      fill='#BDBDBD'
    />
    <rect
      width='0.993675'
      height='6.28525'
      rx='0.496838'
      transform='matrix(-0.696454 -0.717602 -0.696454 0.717602 59.9998 4.98962)'
      fill='#BDBDBD'
    />
  </>
)

export const ArrowShort = createIcon(
  16,
  10,
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8077 1.56948C10.6166 1.37258 10.6166 1.05333 10.8077 0.85642C10.9988 0.659513 11.3087 0.659513 11.4998 0.85642L15.0933 4.55902C15.1258 4.57949 15.1566 4.60424 15.1848 4.63328C15.2807 4.73208 15.3284 4.8617 15.3281 4.9912C15.333 5.12649 15.2854 5.26344 15.1851 5.36673C15.1564 5.39628 15.1251 5.4214 15.0919 5.44208L11.4994 9.14358C11.3083 9.34049 10.9985 9.34049 10.8074 9.14358C10.6163 8.94668 10.6163 8.62743 10.8074 8.43052L13.6916 5.45874H1.16406C0.88792 5.45874 0.664062 5.23488 0.664062 4.95874C0.664062 4.6826 0.88792 4.45874 1.16406 4.45874H13.6118L10.8077 1.56948Z'
      fill='#BDBDBD'
    />
  </>
)

export const SeatSelection = createIcon(
  20,
  12,
  'M5.5 6C6.87844 6 8 4.87844 8 3.5C8 2.12156 6.87844 1 5.5 1C4.12156 1 3 2.12156 3 3.5C3 4.87844 4.12156 6 5.5 6ZM16.5 2H9.5C9.22375 2 9 2.22375 9 2.5V7H2V0.5C2 0.22375 1.77625 0 1.5 0H0.5C0.22375 0 0 0.22375 0 0.5V11.5C0 11.7762 0.22375 12 0.5 12H1.5C1.77625 12 2 11.7762 2 11.5V10H18V11.5C18 11.7762 18.2237 12 18.5 12H19.5C19.7763 12 20 11.7762 20 11.5V5.5C20 3.56687 18.4331 2 16.5 2Z'
)

export const PassengerData = createIcon(
  20,
  15.56,
  'M18.3333 0.222229H1.66667C0.746528 0.222229 0 0.968757 0 1.8889V2.44445H20V1.8889C20 0.968757 19.2535 0.222229 18.3333 0.222229ZM0 14.1111C0 15.0313 0.746528 15.7778 1.66667 15.7778H18.3333C19.2535 15.7778 20 15.0313 20 14.1111V3.55556H0V14.1111ZM12.2222 6.05556C12.2222 5.90278 12.3472 5.77778 12.5 5.77778H17.5C17.6528 5.77778 17.7778 5.90278 17.7778 6.05556V6.61112C17.7778 6.7639 17.6528 6.8889 17.5 6.8889H12.5C12.3472 6.8889 12.2222 6.7639 12.2222 6.61112V6.05556ZM12.2222 8.27778C12.2222 8.12501 12.3472 8.00001 12.5 8.00001H17.5C17.6528 8.00001 17.7778 8.12501 17.7778 8.27778V8.83334C17.7778 8.98612 17.6528 9.11112 17.5 9.11112H12.5C12.3472 9.11112 12.2222 8.98612 12.2222 8.83334V8.27778ZM12.2222 10.5C12.2222 10.3472 12.3472 10.2222 12.5 10.2222H17.5C17.6528 10.2222 17.7778 10.3472 17.7778 10.5V11.0556C17.7778 11.2083 17.6528 11.3333 17.5 11.3333H12.5C12.3472 11.3333 12.2222 11.2083 12.2222 11.0556V10.5ZM6.11111 5.77778C7.33681 5.77778 8.33333 6.77431 8.33333 8.00001C8.33333 9.2257 7.33681 10.2222 6.11111 10.2222C4.88542 10.2222 3.88889 9.2257 3.88889 8.00001C3.88889 6.77431 4.88542 5.77778 6.11111 5.77778ZM2.32986 12.8681C2.62153 11.9757 3.45833 11.3333 4.44444 11.3333H4.72917C5.15625 11.5104 5.62153 11.6111 6.11111 11.6111C6.60069 11.6111 7.06944 11.5104 7.49306 11.3333H7.77778C8.76389 11.3333 9.60069 11.9757 9.89236 12.8681C10.0035 13.2118 9.71181 13.5556 9.35069 13.5556H2.87153C2.51042 13.5556 2.21875 13.2083 2.32986 12.8681Z'
)

export const TicketPayment = createIcon(
  20,
  15.56,
  'M0 14.1111C0 15.0313 0.746528 15.7778 1.66667 15.7778H18.3333C19.2535 15.7778 20 15.0313 20 14.1111V8.00001H0V14.1111ZM6.66667 11.75C6.66667 11.5208 6.85417 11.3333 7.08333 11.3333H11.8056C12.0347 11.3333 12.2222 11.5208 12.2222 11.75V13.1389C12.2222 13.3681 12.0347 13.5556 11.8056 13.5556H7.08333C6.85417 13.5556 6.66667 13.3681 6.66667 13.1389V11.75ZM2.22222 11.75C2.22222 11.5208 2.40972 11.3333 2.63889 11.3333H5.13889C5.36806 11.3333 5.55556 11.5208 5.55556 11.75V13.1389C5.55556 13.3681 5.36806 13.5556 5.13889 13.5556H2.63889C2.40972 13.5556 2.22222 13.3681 2.22222 13.1389V11.75ZM20 1.8889V3.55556H0V1.8889C0 0.968757 0.746528 0.222229 1.66667 0.222229H18.3333C19.2535 0.222229 20 0.968757 20 1.8889Z'
)
