import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { AxiosResponse } from 'axios'
import { SERVICE_TOKEN } from 'core/env'
import { format } from 'date-fns'
import { Calendar, CalendarItem } from 'interfaces/Calendar'
import { PassengerTypes } from 'interfaces/Passenger'

interface Params {
  origin: string
  destination: string
  outboundDate: Date
  inboundDate?: Date
  passengers: Partial<Record<PassengerTypes, number>>
}

interface RequestBody {
  origin: string
  destination: string
  outbound_date: string
  inbound_date?: string
  passengers: Partial<Record<PassengerTypes, number>>
}

export const getCalendar = async ({
  origin,
  destination,
  outboundDate,
  inboundDate,
  passengers: rawPassengers,
}: Params): Promise<Calendar> => {
  const passengers = {
    ...rawPassengers,
    // Remove all kid baby 'cause babies do not need a tickets
    [PassengerTypes.KID_BABY]: 0,
  }

  const body: RequestBody = {
    origin,
    destination,
    outbound_date: format(outboundDate, 'yyyy-MM-dd'),
    passengers,
  }

  if (inboundDate) {
    body.inbound_date = format(inboundDate, 'yyyy-MM-dd')
  }

  const { data } = await baseAxios.post<RequestBody, AxiosResponse<Calendar<string>>>(
    URLS.dayprice,
    body,
    {
      headers: {
        'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
      },
    }
  )

  const formatDayprices = ({ date: datestring, amount }: CalendarItem) => ({
    date: new Date(datestring).toISOString(),
    amount,
  })

  return {
    inbound: data.inbound?.map(formatDayprices) || [],
    outbound: data.outbound?.map(formatDayprices) || [],
  }
}
