import styled from 'styled-components'
import { indent } from 'styles/mixins'

interface WrapperProps {
  isTransparent: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme, isTransparent }) =>
    isTransparent ? theme.palette.core.TRANSPARENT : theme.palette.primary.MAIN};
  ${() => indent('padding', { vertical: 24 })}

  @media screen and (max-width: 1180px) {
    padding: 24px 0px 14px;
  }
`
