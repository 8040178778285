interface CookieOptions {
  path?: string
  domain?: string
  expires?: string | Date
  'max-age'?: number
  secure?: boolean
  samesite?: string
  httpOnly?: boolean
}

const cookieMaxAge = 60 * 60 * 24 * 365 // 1 year in seconds

const defaultOptions = {
  path: '/',
  secure: true,
  'max-age': cookieMaxAge,
}

export const setCookie = (key: string, value: string, options: CookieOptions = {}) => {
  const optionsObj = {
    ...defaultOptions,
    ...options,
  }

  if (options.expires instanceof Date) {
    optionsObj.expires = options.expires.toUTCString()
  }

  document.cookie = Object.keys(optionsObj).reduce((acc, optionKey) => {
    let optionStr = `; ${optionKey}`
    const optionValue = optionsObj[optionKey as keyof CookieOptions]
    if (optionValue !== true) {
      optionStr += `=${optionValue}`
    }

    return acc + optionStr
  }, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
}
