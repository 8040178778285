import { format as dateFnsFormat } from 'date-fns-tz'
import { ProjectLocale } from 'interfaces/ProjectLocale'
import { getLocale } from 'utilities/getLocale'

export const format = (date: Date | string, formatStr: string, locale: ProjectLocale) => {
  try {
    return dateFnsFormat(typeof date === 'string' ? new Date(date) : date, formatStr, {
      locale: getLocale(locale),
    })
  } catch {
    return 'Invalid date format'
  }
}
