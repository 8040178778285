import { enGB, kk, ru } from 'date-fns/locale'
import { ProjectLocale } from 'interfaces/ProjectLocale'

const locales = {
  ru,
  kk,
  en: enGB,
}

export const getLocale = (locale: ProjectLocale) => locales[locale] || locales.ru
