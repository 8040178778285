import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { AxiosResponse } from 'axios'
import { SuccessBookingCreationResponse } from 'interfaces/Booking'
import { Passenger } from 'interfaces/Passenger'

interface TariffCodeItem {
  tariffCode?: string
  carriage?: string
  seat?: string
}

export interface PassengerWithTariffCodes extends Omit<Passenger, 'disabilityType'> {
  items: Array<TariffCodeItem>
}

export interface CreateBookingRequestBody {
  direction: 'inbound' | 'outbound'
  serviceIdentifier: string
  origin: string
  destination: string
  passengers: Array<PassengerWithTariffCodes>
}

const payloadToSnakeCase = (payloads: Array<CreateBookingRequestBody>) =>
  payloads.map(payload => ({
    service_identifier: payload.serviceIdentifier,
    origin: payload.origin,
    destination: payload.destination,
    direction: payload.direction,
    passengers: payload.passengers.map(passenger => ({
      ...passenger,
      items: passenger.items.map(item => ({
        ...item,
        tariff_code: item.tariffCode,
      })),
    })),
  }))

export const createBooking = async (payload: Array<CreateBookingRequestBody>) => {
  const { data } = await baseAxios.post<
    ReturnType<typeof payloadToSnakeCase>,
    AxiosResponse<SuccessBookingCreationResponse>
  >(URLS.createBooking, payloadToSnakeCase(payload))
  return data
}
