import { ProductCode, ProductFamilyId, ProductType, TariffCode } from 'interfaces/Codes'
import { Passenger } from 'interfaces/Passenger'
import { Icon } from 'utilities/getIconsByPropertyCodes'

import { PropertyCode } from './PropertyCodes'

export enum Timezone {
  Almaty = '+0600',
  Atyrau = '+0500',
}

export type InventoryClassCode = '2D' | '2F' | '3P' | '2S' | '2R' | '3S' | '2K' | '3R' | '3D'

export const emptyInventoryClasses = {
  '2D': [],
  '2F': [],
  '2S': [],
  '2R': [],
  '3S': [],
  '2K': [],
  '3R': [],
  '3D': [],
  '3P': [],
}

export interface TicketsList<DateType = string> {
  inbound: Array<Ticket<DateType>>
  outbound: Array<Ticket<DateType>>
}

interface TicketPassenger {
  type: string
  id: string
  disabilityType: string
}

export interface MultiTicket {
  inbound: Ticket | null
  outbound: Ticket
}

export interface PassengerFare {
  passengerId: string
  price: number
  discounts: Array<Record<string, string | number>>
  productCode: ProductCode
  tariffCode: TariffCode
  inventoryClass: InventoryClassCode
}

export interface InventoryItem {
  id: string
  productCode: ProductCode
  productFamilyId: ProductFamilyId
  productType: ProductType
  required?: boolean
  passengerFares: Array<PassengerFare>
}

export interface InventoryClass {
  id: string
  productFamilyId: ProductFamilyId
  currency: string
  price: number
  originalPrice: number
  items: Array<InventoryItem>
  passengers: Array<TicketPassenger>
}

export interface ServiceType {
  code: string
  name: string
}

export interface Ticket<DateType = string> {
  routeId: string
  travelDate: DateType
  departureDateTime: DateType
  departureTimeZone: string
  departureStationName: string
  departureStationUic: string
  arrivalDateTime: DateType
  arrivalTimeZone: string
  arrivalStationName: string
  arrivalStationUic: string
  travelDuration: string
  serviceName: string
  serviceType: ServiceType
  serviceIdentifier: string
  inventoryClasses: Record<InventoryClassCode, Array<InventoryClass>>
  coupeMinimalPrice: number
  platzkartMinimalPrice: number
  propertyCodes: Array<PropertyCode>
  passengers: Array<Passenger>
}

export interface TrainTicket {
  departureDate: string
  departureTime: string
  departureTimeZone: string
  departureTzPrompt: string
  arrivalTimeZone: string
  arrivalTzPrompt: string
  departureStationName: string
  arrivalDate: string
  arrivalTime: string
  arrivalStationName: string
  coupeMinimalPrice: number
  platzkartMinimalPrice: number
  minimalPrice: number
  travelTime: string
  serviceName: string
  serviceIdentifier: string
  serviceType: ServiceType
  icons: Array<Icon>
  onSelect: () => void
}

export interface TrainMultiTicket {
  outboundDepartureDate: string
  outboundDepartureTime: string
  outboundArrivalDate: string
  outboundArrivalTime: string
  inboundDepartureDate: string
  inboundDepartureTime: string
  inboundArrivalDate: string
  inboundArrivalTime: string
  outbound: Ticket
  inbound: Ticket
  outboundTravelTime: string
  inboundTravelTime: string
  outboundIcons: Array<Icon>
  inboundIcons: Array<Icon>
  onSelect: () => void
}
