import { createAsyncThunk } from '@reduxjs/toolkit'
import { getService } from 'api/requests/getService'

interface Params {
  serviceName: string
  date: Date
  origin: string
  destination: string
}

export const fetchCarriagesList = createAsyncThunk('fetchCarriages', async (params: Params) => {
  const response = await getService(params)
  return response
})
