import { createAsyncThunk } from '@reduxjs/toolkit'
import { getRefundData } from 'api/requests/getRefundData'

export const getRefundDataAction = createAsyncThunk(
  'fetchRefundTicketsData',
  async (bookingId: string) => {
    const response = await getRefundData(bookingId)
    return response
  }
)
