import { createGlobalStyle } from 'styled-components'
import { fonts } from 'styles/core/fonts'
import { resetStyles } from './reset'
import { globalStyles } from './global'

export const GlobalStyle = createGlobalStyle`
  ${resetStyles()}
  ${fonts()}
  ${globalStyles()}
`
