import styled from 'styled-components'
import { makeElementSize } from 'utilities/makeElementSize'

import { Props } from './props'

export const Wrapper = styled.div<Props>`
  width: ${({ width }) => makeElementSize(width)};
  height: ${({ height }) => makeElementSize(height)};
  margin: 0 7vw;

  @media screen and (min-width: 1440px) {
    width: 1140px;
    margin: 0 auto;
  }
  @media screen and (max-width: 576px) {
    margin: 0 16px;
  }
`
