import React, { FC, SVGProps } from 'react'
import { Icon as IIcon } from 'styles/theme/icons'
import { AnyColor } from 'styles/theme/palette'

import { createSvgPath } from './libs/createSvgPath'
import { Wrapper } from './styles'

interface Props extends SVGProps<SVGSVGElement> {
  icon: IIcon
  size?: number
  width?: number
  height?: number
  color?: AnyColor
  colorProperty?: 'fill' | 'stroke'
}

export const Icon: FC<Props> = ({
  icon,
  size,
  width,
  height,
  color,
  colorProperty = 'fill',
  className = '',
  ...rest
}: Props) => {
  const { svgParams, path } = icon

  const svgWidth = width || size || icon.width
  const svgHeight = height || size || icon.height

  const viewBox = `0 0 ${icon.width} ${icon.height}`
  return (
    <Wrapper
      className={`icon-wrapper ${className}-wrapper`}
      width={svgWidth}
      height={svgHeight}
      color={color}
      colorProperty={colorProperty}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={`${svgWidth}px`}
        height={`${svgHeight}px`}
        viewBox={viewBox}
        className={`icon ${className}`}
        {...rest}
        {...svgParams}
      >
        {createSvgPath(path)}
      </svg>
    </Wrapper>
  )
}
