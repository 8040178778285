export enum Title {
  H1 = 'Title_H1',
  H2 = 'Title_H2',
  H3 = 'Title_H3',
}

export enum Content {
  MAIN = 'Content_Main',
  BOLD = 'Content_Bold',
}

export enum Description {
  MAIN = 'Description_Main',
  BOLD = 'Description_Bold',
}

export enum Button {
  MAIN = 'Button_Main',
  LIGHT = 'Button_Light',
}
