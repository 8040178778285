import { SuccessBookingPassengerProduct } from 'interfaces/Booking'
import { Passenger } from 'interfaces/Passenger'
import { SelectSeatStepType } from 'interfaces/SelectSeatStepType'
import { Ticket } from 'interfaces/Ticket'

export enum AnalyticsEventType {
  PAGELOAD = 'page_load',
  TICKET_SEARCH_RESULT = 'ticket search result',
  RESULTS = 'results event',
  CHECKOUT = 'checkout',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  AUTH = 'auth',
}

export enum PaymentStatus {
  Success = 'success',
  Failure = 'failure',
}

export interface SelectSeatEventData {
  type: SelectSeatStepType
  bedding: boolean
  ticket: Ticket
  passengers: Passenger[]
  seats: Record<string, Array<SuccessBookingPassengerProduct>>
  seatSelectionPrice: number
}

export interface AnalyticsEvent {
  event: AnalyticsEventType
  eventProperties: {
    [key: string]: string
  }
}

export interface RouteSearchEvent extends AnalyticsEvent {
  searchProperties: {
    searchDestinationName: string
    searchOriginName: string
    searchOutboundDate: string
    searchReturnDate: string | null
  }
  ticketProducts: {
    adultPassengers: number
    kidPassengers: number
    preferentialPassengers: number
  }
}

export interface ECommerceEvent extends AnalyticsEvent {
  routeType: SelectSeatStepType
  ecommerce: {
    currencyCode: string
  }
}

export interface ECommerceCheckoutEvent extends AnalyticsEvent {
  ecommerce: {
    checkout: {
      actionField: {
        step: number
      }
    }
  }
}

export interface ECommercePaymentEvent extends AnalyticsEvent {
  status: PaymentStatus
  ecommerce: {
    purchase: {
      actionField: {
        id: string
        revenue: number
      }
      products: Array<Record<string, string | number>>
    }
  }
}

export interface ECommerceRefundEvent extends AnalyticsEvent {
  ecommerce: {
    refund: {
      actionField: {
        id: string
        tax: number
      }
      products: Array<Record<string, string | number>>
    }
  }
}

interface DataLayer {
  [key: number]: AnalyticsEvent
  push: (event: AnalyticsEvent & { environment: string }) => void
}

declare global {
  interface Window {
    dataLayer: DataLayer
  }

  interface Navigator {
    userAgentData: {
      platform: string
    }
  }
}
