import { css } from 'styled-components'
import { isNumber } from 'utilities/isNumber'
import { pixelize } from 'utilities/pixelize'

export interface FlexContainerParams {
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'space-between' | 'space-around'
  direction?: 'column' | 'row' | 'column-reverse'
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  wrap?: 'wrap' | 'nowrap'
  gap?: string | number
  rowGap?: string | number
  columnGap?: string | number
}

export const flexContainer = ({
  alignItems,
  alignContent,
  direction,
  justify,
  wrap,
  gap,
  rowGap,
  columnGap,
}: FlexContainerParams = {}) => css`
  display: flex;
  ${wrap ? `flex-wrap: ${wrap};` : ''}
  ${direction ? `flex-direction: ${direction};` : ''}
  ${justify ? `justify-content: ${justify};` : ''}
  ${alignItems ? `align-items: ${alignItems};` : ''}
  ${alignContent ? `align-content: ${alignContent};` : ''}
  ${gap ? `gap: ${isNumber(gap) ? pixelize(gap) : gap};` : ''}
  ${rowGap ? `row-gap: ${isNumber(rowGap) ? pixelize(rowGap) : rowGap};` : ''}
  ${columnGap ? `column-gap: ${isNumber(columnGap) ? pixelize(columnGap) : columnGap};` : ''}
`
