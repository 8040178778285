export enum ToastType {
  BookingDataFillExpired,
  SeatChangedDuringBooking,
  UnexpectedPaymentApprove,
  BookingAlreadyConfirmed,
  EmailTicketsFailure,
  RefundWarning,
  UnexpectedError,
  TooFastPayment,
  EmailTicketSuccessfullySent,
  TicketIsNotReady,
  Copied,
  Updated,
}
