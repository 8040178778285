import { Icon } from 'components/ui/Icon'
import setLanguage from 'next-translate/setLanguage'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import Select, { components, OptionProps } from 'react-select'
import { theme } from 'styles/theme'

import { Wrapper } from './styles'

const { Option: ReactSelectOption } = components

interface Option {
  label: string
  value: string
}

interface CustomOptionProps extends OptionProps {
  label: string
}

const CustomSelectOption = ({ label, ...props }: CustomOptionProps) => (
  <ReactSelectOption {...props} label={label}>
    {label}
    <Icon
      icon={theme.icons.core.Checkbox}
      size={11}
      color={theme.palette.core.WHITE}
      className='selected-icon'
    />
  </ReactSelectOption>
)

export const LanguageSelector: FC = () => {
  const { lang } = useTranslation()

  const languages: Array<Option> = [
    {
      value: 'ru',
      label: 'Рус',
    },
    {
      value: 'kk',
      label: 'Қаз',
    },
  ]

  const activeLanguage = languages.find(l => l.value === lang)

  const handleSwitch = async (option: Option | null) => {
    if (!option) return
    await setLanguage(option.value)
  }

  return (
    <Wrapper>
      <Select
        instanceId='language-select'
        isSearchable={false}
        classNamePrefix='language-select'
        value={activeLanguage}
        onChange={option => handleSwitch(option as Option)}
        options={languages}
        components={{ Option: CustomSelectOption }}
      />
    </Wrapper>
  )
}
