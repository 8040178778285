import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useState } from 'react'

export const useHeaderMobileMenu = () => {
  const [isOpen, setState] = useState(false)

  const open = () => {
    setState(true)
    disableBodyScroll(document.body)
  }

  const close = () => {
    setState(false)
    enableBodyScroll(document.body)
  }

  return {
    open,
    close,
    isOpen,
  }
}
