import { Flex } from 'components/ui/Flex'
import styled from 'styled-components'

interface Props {
  isOpen: boolean
}

export const Wrapper = styled.div<Props>`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  transition: 0.3s ease-out;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    z-index: 9999;
    background: rgb(0 0 0 / 30%);
  `}
`

export const Navbar = styled.div<Props>`
  height: 100%;
  width: 270px;
  position: absolute;
  top: 0;
  right: -270px;
  background-color: #1e3656;
  transition: 0.3s ease-out;

  display: flex;
  flex-direction: column;
  row-gap: 42px;

  ${({ isOpen }) =>
    isOpen &&
    `
    right: 0;
  `}
`

export const NavList = styled(Flex)`
  padding: 0 62px 18px 38px;
`

export const StyledFlex = styled(Flex)`
  padding: 18px 18px 0;

  div {
    cursor: pointer;
  }
`
