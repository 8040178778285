import { DesktopDropdown } from 'components/shared/Header/libs/DesktopDropdown'
import { LanguageSelector } from 'components/shared/Header/libs/LanguageSelector'
import { NavLink } from 'components/shared/Header/libs/NavLink'
import { Phone } from 'components/shared/Header/libs/Phone'
import { HeaderLink } from 'components/shared/Header/types'
import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import Link from 'next/link'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import { Separator, Wrapper } from './styles'

interface Props {
  links: Array<HeaderLink>
}

export const DesktopHeader: FC<Props> = ({ links }) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Flex container alignItems='center' justify='space-between'>
        <Link href='/'>
          <a>
            <Icon icon={theme.icons.core.Logo} color={theme.palette.core.WHITE} />
          </a>
        </Link>
        <Flex container alignItems='center' columnGap={32}>
          <Flex container alignItems='center' justify='center' columnGap={32}>
            {links.map(({ url, text, childLinks }) =>
              childLinks ? (
                <DesktopDropdown key={text} text={text} links={childLinks} />
              ) : (
                <NavLink key={text} title={text} href={url}>
                  {text}
                </NavLink>
              )
            )}
          </Flex>
          <Separator />
          <Flex item>
            <LanguageSelector />
          </Flex>
          <Separator />
          <Phone />
        </Flex>
      </Flex>
    </Wrapper>
  )
}
