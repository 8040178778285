import { css } from 'styled-components'
import { isNumber } from 'utilities/isNumber'
import { pixelize } from 'utilities/pixelize'

export interface FlexItemParams {
  order?: number
  grow?: number
  shrink?: number
  basis?: number | string
  alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
}

export const flexItem = ({ order, grow, shrink, basis, alignSelf }: FlexItemParams = {}) => css`
  ${order ? `order: ${order};` : ''}
  flex-grow: ${isNumber(grow) ? grow : 1};
  flex-shrink: ${isNumber(shrink) ? shrink : 1};
  flex-basis: ${basis && isNumber(basis) ? pixelize(basis) : basis || 'auto'};
  ${alignSelf ? `align-self: ${alignSelf};` : ''}
`
