export enum Sizes {
  XS = 12,
  DEFAULT = 14,
  LG = 16,
  XL = 18,
  XXL = 26,
}

export enum Weights {
  REGULAR = 400,
  BOLD = 700,
}
