import axios from 'axios'
import camelize from 'camelize-ts'
import { SERVICE_TOKEN } from 'core/env'
import { ProjectLocale } from 'interfaces/ProjectLocale'
import { getLanguageHeaderByLocale } from 'utilities/getLanguageHeaderByLocale'

const IS_SERVER = typeof window === 'undefined'
const LOCALE_COOKIE_NAME = 'NEXT_LOCALE'

function getCookieValueByName<T extends string>(name: string): T {
  const match = new RegExp(`(^| )${name}=([^;]+)`).exec(document.cookie)
  return (match?.[2] || 'ru') as T
}

const baseAxios = axios.create()

baseAxios.interceptors.request.use(request => {
  if (IS_SERVER)
    return {
      ...request,
      headers: {
        ...request.headers,
        'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
      },
    }

  const translateCookie = getCookieValueByName<ProjectLocale>(LOCALE_COOKIE_NAME)
  const acceptLanguage = getLanguageHeaderByLocale(translateCookie)
  return {
    ...request,
    headers: {
      ...request.headers,
      'Accept-Language': acceptLanguage,
      'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
    },
  }
})

baseAxios.interceptors.response.use(response => ({
  ...response,
  data: camelize(response.data),
}))

export default baseAxios
