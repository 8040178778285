import { Box } from 'components/ui/Box'
import { Flex } from 'components/ui/Flex'
import { Paper } from 'components/ui/Paper'
import styled from 'styled-components'
import { flexContainer, indent } from 'styles/mixins'

export const Wrapper = styled.div`
  ${() => indent('padding', { top: 56, bottom: 66 })}
  border-top: 1px solid ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};

  @media screen and (max-width: 1260px) {
    ${() => indent('padding', { vertical: 32 })}
  }
`

export const FooterLinkWrapper = styled.div`
  span {
    transition: all 0.2s ease;
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.MAIN};
    }
  }
`

export const FlexContainer = styled(Flex)`
  @media screen and (max-width: 1260px) {
    column-gap: 0;

    ${flexContainer({
      direction: 'column-reverse',
    })}
  }
`

export const NavbarFlexContainer = styled(Flex)`
  @media screen and (max-width: 1260px) {
    width: 100%;
  }
`

export const Navbar = styled(Flex)`
  justify-content: space-between;

  @media screen and (max-width: 1260px) {
    width: 100%;

    ${flexContainer({
      direction: 'column',
    })}
  }
`

export const NavbarItem = styled(Box)`
  @media screen and (max-width: 1260px) {
    width: 100%;

    &:nth-child(2) {
      margin: 16px 0;
      padding: 16px 0;
      border-top: 1px solid ${({ theme }) => theme.palette.core.GRAY};
      border-bottom: 1px solid ${({ theme }) => theme.palette.core.GRAY};
    }
  }
`

export const PaymentItem = styled(Box)`
  @media screen and (max-width: 1260px) {
    width: 100%;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${({ theme }) => theme.palette.core.GRAY};
  }
`

export const SocialNetworkIcons = styled(Flex)`
  @media screen and (max-width: 1260px) {
    width: 100%;
    margin-top: 40px;
  }
`

export const SocialNetworkIconsBox = styled(Box)`
  @media screen and (max-width: 1260px) {
    width: 100%;

    ${flexContainer({
      justify: 'space-between',
      alignItems: 'flex-end',
    })}
  }
`

export const LogoBox = styled(Box)`
  width: 70px;

  > div {
    width: 70px;
  }

  @media screen and (max-width: 1260px) {
    margin-bottom: 0;
  }
`

export const Payment = styled(Paper)`
  width: 68px;
  height: 35px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
