import styled, { css } from 'styled-components'

interface Props {
  isOpened: boolean
}

export const Wrapper = styled.div<Props>`
  .hide-is-dropdown-open {
    opacity: 1;
    transition: all 0.3s ease;
    ${({ isOpened }) =>
      isOpened &&
      css`
        opacity: 0;
      `}
  }

  .ReactCollapse--collapse {
    transition: height 0.3s ease;
  }
`

export const Title = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  > span {
    font-size: 16px;
  }

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    left: -22px;
    opacity: 0;
    transition: opacity 0.2s;
    transform: translateY(-50%);
    background-color: #e43c4a;

    ${({ isActive }) =>
      isActive &&
      `
        opacity: 1;
    `}
  }
`

export const IconWrapper = styled.span<Props>`
  display: block;
  margin-left: 10px;
  height: 11px;
  > div {
    transition: all 0.3s ease;
  }
  ${({ isOpened }) =>
    isOpened &&
    css`
      > div {
        transform: rotate(180deg);
      }
    `}
`
