import authAxios from 'api/axios/authAxios'
import { URLS } from 'api/urls'
import { SERVICE_TOKEN } from 'core/env'
import { Booking } from 'interfaces/Booking'

export const getBookingHistory = async (): Promise<Booking[]> => {
  const { data } = await authAxios.get<Booking[]>(URLS.getBookingHistory, {
    params: {
      limit: 1000,
    },
    headers: {
      'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
    },
  })

  return data
}
