import { AnalyticsEvent } from 'interfaces/GoogleAnalytics'

export const pushEvent = (event: AnalyticsEvent) => {
  const currentEnv = process.env.NODE_ENV

  if (currentEnv === 'development' || typeof window === 'undefined') return false

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    ...event,
    environment: currentEnv,
  })

  return true
}
