import { createAsyncThunk } from '@reduxjs/toolkit'
import { captureException } from '@sentry/nextjs'
import { selectSeatsEvent } from 'analytics/events/selectSeats'
import {
  createBooking,
  CreateBookingRequestBody,
  PassengerWithTariffCodes,
} from 'api/requests/createBooking'
import {
  BookingCreationWarningMessage,
  BookingCreationWarningMessageCode,
  SuccessBookingPassenger,
} from 'interfaces/Booking'
import { Carriage, CarriageLayoutType, CarriageSeatWithCarriage } from 'interfaces/Carriage'
import { ProductCode, seatSelectionTariffCodes } from 'interfaces/Codes'
import { Passenger } from 'interfaces/Passenger'
import { SelectSeatStepType } from 'interfaces/SelectSeatStepType'
import { InventoryClass, Ticket } from 'interfaces/Ticket'
import { RootState } from 'store/core'
import { calculateSeatSelectionPrice } from 'store/features/passengers/selectors'
import { getIsSingleRide } from 'store/features/ticketsSearch/selectors'

import { getCheapestInventoryClass } from './utilities/getCheapestInventoryClass'
import { getSeatInventoryClass } from './utilities/getSeatInventoryClass'

export const getTariffCodes = (inventoryClass: InventoryClass | null, passenger: Passenger) =>
  inventoryClass
    ? inventoryClass.items.map(inventoryItem => {
        const fare = inventoryItem.passengerFares.find(f => f.passengerId === passenger.id)
        if (!fare) return null
        return {
          isTicket: Boolean(fare.inventoryClass),
          tariffCode: fare.tariffCode,
          productCode: fare.productCode,
          inventoryProductCode: inventoryItem.productCode,
        }
      })
    : []

const buildManualTariffCodes = (
  passengers: Array<Passenger>,
  seats: Record<string, CarriageSeatWithCarriage>,
  ticket: Ticket,
  useBedding: boolean,
  carriages: Array<Carriage>
): Array<PassengerWithTariffCodes> =>
  passengers.map(passenger => {
    const seat = seats[passenger.id]
    const inventoryClass = getSeatInventoryClass(seat, ticket)

    const tariffCodes = getTariffCodes(inventoryClass, passenger)

    const seatCarriage = carriages.find(carriage => carriage.carriageNumber === seat.carriage)
    const isCoupeSeat = Boolean(seatCarriage?.isCoupe)

    return {
      ...passenger,
      items: tariffCodes
        .filter(
          codes =>
            !(!codes || (codes.productCode === ProductCode.BEDLIN && !useBedding && !isCoupeSeat))
        )
        .map(codes => {
          if (codes?.isTicket)
            return {
              carriage: seat.carriage,
              seat: seat.seatNumber,
              tariffCode: codes?.tariffCode,
            }

          return {
            tariffCode: codes?.tariffCode,
          }
        }),
    }
  })

const buildAutoTariffCodes = (
  passengers: Array<Passenger>,
  seats: Record<string, CarriageSeatWithCarriage>,
  ticket: Ticket,
  useBedding: boolean,
  filter: CarriageLayoutType | null
): Array<PassengerWithTariffCodes> =>
  passengers.map(passenger => {
    const cheapestInventoryClass = getCheapestInventoryClass(ticket.inventoryClasses, filter)
    const tariffCodes = getTariffCodes(cheapestInventoryClass, passenger)

    const isCoupe = filter === 'coupe'

    // Seat selection tariff codes to exclude from auto seat selection
    const tariffCodesToExclude = [...seatSelectionTariffCodes]

    return {
      ...passenger,
      items: tariffCodes
        .filter(
          codes =>
            !(!codes || (codes.productCode === ProductCode.BEDLIN && !useBedding && !isCoupe))
        )
        .filter(codes => codes?.tariffCode && !tariffCodesToExclude.includes(codes.tariffCode))
        .map(codes => ({
          tariffCode: codes?.tariffCode,
        })),
    }
  })

const buildPayload = (
  isSingleRide: boolean,
  passengersData: RootState['passengers'],
  carriages: Array<Carriage>
) => {
  const initial: Array<SelectSeatStepType> = isSingleRide ? ['outbound'] : ['outbound', 'inbound']
  return initial
    .map(type => {
      const { passengers, seats, manualAllocation, bedding, filter } = passengersData[type]
      const ticket = passengersData.ticket?.[type] as Ticket

      const passengersWithTarrifCodes = manualAllocation
        ? buildManualTariffCodes(passengers, seats, ticket, bedding, carriages)
        : buildAutoTariffCodes(passengers, seats, ticket, bedding, filter)

      return {
        serviceIdentifier: ticket.serviceIdentifier,
        origin: ticket.departureStationUic,
        destination: ticket.arrivalStationUic,
        direction: type,
        passengers: passengersWithTarrifCodes,
      }
    })
    .filter(payload => Boolean(payload)) as Array<CreateBookingRequestBody>
}

const buildAnalyticsData = (isSingleRide: boolean, passengersData: RootState['passengers']) => {
  const initial: Array<SelectSeatStepType> = isSingleRide ? ['outbound'] : ['outbound', 'inbound']

  return initial
    .map(type => {
      const { passengers, seats, bedding, manualAllocation } = passengersData[type]
      const ticket = passengersData.ticket?.[type] as Ticket

      return {
        type,
        passengers,
        bedding,
        ticket,
        seatSelectionPrice: calculateSeatSelectionPrice(
          seats,
          passengers,
          ticket,
          manualAllocation
        ),
      }
    })
    .filter(payload => Boolean(payload))
}

const getSeatChangedStatus = (messages: Array<BookingCreationWarningMessage>) =>
  Boolean(
    messages.findIndex(
      message => message.code && message.code === BookingCreationWarningMessageCode.SeatChanged
    ) + 1
  )

const getDirectionSeats = (passengersObj: Record<string, SuccessBookingPassenger>) =>
  Object.keys(passengersObj).reduce((acc, currVal) => {
    const productWithSeat = passengersObj[currVal].products
    return {
      ...acc,
      [currVal]: productWithSeat,
    }
  }, {})

export const postCreateBooking = createAsyncThunk(
  'createBooking',
  async (_: string, { getState }) => {
    try {
      const state = getState() as RootState
      const {
        passengers,
        carriages: { entities: carriages },
      } = state
      const { ticket } = passengers

      const isSingleRide = getIsSingleRide(state)
      if (!ticket) throw new Error('Cannot create booking without ticket')
      const payload = buildPayload(isSingleRide, passengers, carriages)
      const { bookingNumber, messages, inboundPassengers, outboundPassengers } =
        await createBooking(payload)

      const analyticsData = buildAnalyticsData(isSingleRide, passengers)
      const analyticsDataWithSeats = analyticsData
        .map(item => {
          if (!item) return null
          return {
            ...item,
            seats: getDirectionSeats(
              item.type === 'inbound' ? inboundPassengers : outboundPassengers
            ),
          }
        })
        .filter(item => Boolean(item))

      analyticsDataWithSeats.forEach(data => {
        if (data) {
          // /* Analytics event */
          selectSeatsEvent(data, bookingNumber)
        }
      })
      const isSeatChanged = getSeatChangedStatus(messages)
      return {
        bookingNumber,
        isSeatChanged,
      }
    } catch (e) {
      captureException(e)
      const message = 'Error during booking creation'
      throw new Error(message)
    }
  }
)
