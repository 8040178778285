import { reducer as authorization } from 'store/features/authorization'
import { reducer as booking } from 'store/features/booking'
import { reducer as bookingHistory } from 'store/features/bookingHistory'
import { reducer as bookingPayment } from 'store/features/bookingPayment'
import { reducer as bookingUpdate } from 'store/features/bookingUpdate'
import { reducer as carriages } from 'store/features/carriages'
import { reducer as gateway } from 'store/features/gateway'
import { reducer as passengers } from 'store/features/passengers'
import { reducer as pricesCalendar } from 'store/features/pricesCalendar'
import { reducer as refund } from 'store/features/refund'
import { reducer as schedule } from 'store/features/schedule'
import { reducer as stations } from 'store/features/stations'
import { reducer as ticketsSearch } from 'store/features/ticketsSearch'

export const rootReducer = {
  passengers,
  stations,
  ticketsSearch,
  carriages,
  pricesCalendar,
  booking,
  bookingHistory,
  bookingUpdate,
  bookingPayment,
  refund,
  schedule,
  gateway,
  authorization,
}
