import { TokenStorage } from 'auth/TokenStorage'
import { PageLayout } from 'components/layouts/PageLayout'
import { usePersistLocale } from 'hooks/usePersistLocale'
import { AppProps, CustomPageProps } from 'interfaces/Next'
import Head from 'next/head'
import appWithI18n from 'next-translate/appWithI18n'
import { Provider } from 'react-redux'
import { createStore } from 'store/core'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from 'styles/core'
import { theme } from 'styles/theme'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18nConfig from '../i18n.js'

const MyApp = ({ Component, pageProps, router }: AppProps<CustomPageProps>) => {
  usePersistLocale()

  const { preloadedState } = pageProps

  const tokenStorage = TokenStorage.getInstance()
  const { accessToken, refreshToken } = tokenStorage.getClientTokensFromCookie()
  tokenStorage.setAccessToken(accessToken)
  tokenStorage.setRefreshToken(refreshToken)

  const store = createStore(preloadedState)

  const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://bile-bile.kz'
  const KZ_SITE_URL = `${SITE_URL}/kk`
  const RU_SITE_URL = `${SITE_URL}`

  const path = router.asPath.slice(-1) === '/' ? router.asPath.slice(0, -1) : router.asPath

  return (
    <>
      <Head>
        <link
          rel='canonical'
          key='canonical-link'
          href={`${router.locale === 'ru' ? RU_SITE_URL : KZ_SITE_URL}${path}`}
        />
        <link
          key='hreflang-default'
          rel='alternate'
          hrefLang='x-default'
          href={`${SITE_URL}${path}`}
        />
        <link key='hreflang-ru' rel='alternate' hrefLang='ru-KZ' href={`${RU_SITE_URL}${path}`} />
        <link key='hreflang-kk' rel='alternate' hrefLang='kk-KZ' href={`${KZ_SITE_URL}${path}`} />
        <title key='title'>Bile-Bile</title>
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
      </Head>
      <GlobalStyle theme={theme} />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PageLayout>
            <Component {...pageProps} />
          </PageLayout>
        </ThemeProvider>
      </Provider>
    </>
  )
}

export default appWithI18n(MyApp, i18nConfig)
