import axios, {
  Axios,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

interface CustomAxiosInterceptorManager<V> extends AxiosInterceptorManager<V> {
  handlers: Array<V>
}

export interface AxiosInstanceToExtend extends Axios {
  interceptors: {
    request: CustomAxiosInterceptorManager<AxiosRequestConfig>
    response: CustomAxiosInterceptorManager<AxiosResponse>
  }
}

export default function extendAxiosInstance(
  baseInstance: AxiosInstance,
  newConfig: AxiosRequestConfig
): AxiosInstance {
  const newInstance = axios.create({
    ...baseInstance.defaults,
    ...newConfig,
  })

  const baseInstanceAsExtended = baseInstance as unknown as AxiosInstanceToExtend

  baseInstanceAsExtended.interceptors.request.handlers.forEach(
    (interceptor: AxiosRequestConfig) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      newInstance.interceptors.request.use(...Object.values(interceptor))
    }
  )

  baseInstanceAsExtended.interceptors.response.handlers.forEach((interceptor: AxiosResponse) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    newInstance.interceptors.response.use(...Object.values(interceptor))
  })

  return newInstance
}
