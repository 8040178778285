import { createSlice } from '@reduxjs/toolkit'
import { Booking, BookingState } from 'interfaces/Booking'
import { InternalProduct } from 'interfaces/InternalProducts'

import { getBookingAction } from './actions'

export interface BookingReducerState {
  isLoading: boolean
  isError: boolean
  booking: Booking
  internalProducts: Array<InternalProduct>
}

const initialBooking: Booking = {
  createTimestamp: '',
  expiresAt: '',
  customer: null,
  bookingNumber: '',
  inboundBookingTariffSegments: [],
  outboundBookingTariffSegments: [],
  internalProducts: [],
  passengers: [],
  smsNotifications: false,
  status: BookingState.Provisional,
  totalPrice: 0,
  isRefundPending: false,
}

export const initialState: BookingReducerState = {
  booking: initialBooking,
  isLoading: false,
  isError: false,
  internalProducts: [],
}

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBookingAction.pending, state => ({
        ...state,
        isLoading: true,
        isError: false,
      }))
      .addCase(getBookingAction.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        isError: false,
        booking: action.payload,
      }))
      .addCase(getBookingAction.rejected, state => ({
        ...state,
        isLoading: false,
        isError: true,
        booking: initialBooking,
      }))
  },
})

export const { reducer } = bookingSlice
