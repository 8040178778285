import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CarriageLayoutType, CarriageSeatWithCarriage } from 'interfaces/Carriage'
import { Passenger } from 'interfaces/Passenger'
import { SelectSeatStepType } from 'interfaces/SelectSeatStepType'
import { MultiTicket, Ticket } from 'interfaces/Ticket'
import { postCreateBooking } from 'store/features/passengers/actions'

export interface SinglePassengersState {
  passengers: Array<Passenger>
  manualAllocation: boolean
  bedding: boolean
  filter: CarriageLayoutType | null
  discountSeatsAvailability: Record<CarriageLayoutType, boolean>
  seats: Record<string, CarriageSeatWithCarriage>
}

type PassengersState = Record<SelectSeatStepType, SinglePassengersState> & {
  currentStepType: SelectSeatStepType
  ticket: MultiTicket | null
  bookingCreationState: {
    createdBookingId: string | null
    isSeatChanged: boolean
    isLoading: boolean
    isError: boolean
    isSuccess: boolean
  }
  highlightAllocationCheckbox: boolean
}

const initialSingleState: SinglePassengersState = {
  passengers: [],
  manualAllocation: true,
  bedding: true,
  filter: null,
  discountSeatsAvailability: {
    coupe: true,
    platzkart: true,
  },
  seats: {},
}

const initialState: PassengersState = {
  currentStepType: 'outbound',
  ticket: null,
  outbound: initialSingleState,
  inbound: initialSingleState,
  bookingCreationState: {
    createdBookingId: null,
    isSeatChanged: false,
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  highlightAllocationCheckbox: false,
}

const makeUpdatedPassengers = (ticket: Ticket | null, state: SinglePassengersState) => {
  if (!ticket) return state
  return {
    ...state,
    passengers: ticket.passengers,
    seats: {},
  }
}

const getStateByType = (state: PassengersState, type: SelectSeatStepType) => {
  if (type === 'inbound') return state.inbound
  return state.outbound
}

export const passengersSlice = createSlice({
  name: 'passengers',
  initialState,
  reducers: {
    setTicket: (state, action: PayloadAction<MultiTicket | null>) => ({
      ...state,
      ticket: action.payload,
    }),

    setCurrentStepType: (state, action: PayloadAction<SelectSeatStepType>) => ({
      ...state,
      currentStepType: action.payload,
    }),

    toggleBedding: (state, action: PayloadAction<{ type: SelectSeatStepType }>) => {
      const { type } = action.payload
      return {
        ...state,
        [type]: {
          ...state[type],
          bedding: !state[type].bedding,
        },
      }
    },

    toggleManualAllocation: (state, action: PayloadAction<{ type: SelectSeatStepType }>) => {
      const { type } = action.payload
      return {
        ...state,
        [type]: {
          ...state[type],
          seats: {},
          manualAllocation: !state[type].manualAllocation,
        },
      }
    },

    setSeats: (
      state,
      action: PayloadAction<{
        type: SelectSeatStepType
        seats: Record<string, CarriageSeatWithCarriage>
      }>
    ) => {
      const { type, seats } = action.payload
      return {
        ...state,
        [type]: {
          ...state[type],
          seats,
        },
      }
    },

    setFilter: (
      state,
      action: PayloadAction<{ type: SelectSeatStepType; value: CarriageLayoutType | null }>
    ) => {
      const { type, value } = action.payload
      return {
        ...state,
        [type]: {
          ...state[type],
          filter: value,
        },
      }
    },

    setDiscountSeatsAvailability: (
      state,
      action: PayloadAction<{
        type: SelectSeatStepType
        value: SinglePassengersState['discountSeatsAvailability']
      }>
    ) => {
      const { type, value } = action.payload
      return {
        ...state,
        [type]: {
          ...state[type],
          discountSeatsAvailability: value,
        },
      }
    },

    updatePassengers: (state, action: PayloadAction<MultiTicket>) => {
      const { payload: ticket } = action
      const inbound = makeUpdatedPassengers(ticket.inbound, state.inbound)
      const outbound = makeUpdatedPassengers(ticket.outbound, state.outbound)

      return {
        ...state,
        inbound,
        outbound,
      }
    },

    addSeat: (
      state,
      action: PayloadAction<{
        passenger: Passenger
        seat: CarriageSeatWithCarriage
        type: SelectSeatStepType
      }>
    ) => {
      const { passenger, seat, type } = action.payload
      const { seats } = getStateByType(state, type)
      const newSeats = { ...seats, [passenger.id]: seat }

      return {
        ...state,
        [type]: {
          ...state[type],
          seats: newSeats,
        },
      }
    },

    removeSeat: (
      state,
      action: PayloadAction<{
        passenger: Passenger
        type: SelectSeatStepType
      }>
    ) => {
      const { passenger, type } = action.payload
      const { seats } = getStateByType(state, type)

      const newSeats = Object.entries(seats).reduce((acc, [id, seat]) => {
        if (passenger.id === id) return acc
        return {
          ...acc,
          [id]: seat,
        }
      }, {})

      return {
        ...state,
        [type]: {
          ...state[type],
          seats: newSeats,
        },
      }
    },

    toggleHighlightAllocation: state => {
      return {
        ...state,
        highlightAllocationCheckbox: !state.highlightAllocationCheckbox,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(postCreateBooking.pending, state => ({
      ...state,
      bookingCreationState: {
        ...state.bookingCreationState,
        isLoading: true,
        isError: false,
      },
    }))

    builder.addCase(postCreateBooking.fulfilled, (state, action) => ({
      ...state,
      bookingCreationState: {
        ...state.bookingCreationState,
        createdBookingId: action.payload.bookingNumber,
        isSeatChanged: action.payload.isSeatChanged,
        isLoading: false,
        isSuccess: true,
        isError: false,
      },
    }))

    builder.addCase(postCreateBooking.rejected, state => ({
      ...state,
      bookingCreationState: {
        ...state.bookingCreationState,
        createdBookingId: null,
        isSeatChanged: false,
        isLoading: false,
        isError: true,
      },
    }))
  },
})

export const {
  setTicket,
  updatePassengers,
  addSeat,
  removeSeat,
  setFilter,
  setSeats,
  setDiscountSeatsAvailability,
  toggleBedding,
  toggleManualAllocation,
  setCurrentStepType,
  toggleHighlightAllocation,
} = passengersSlice.actions
export { postCreateBooking }
export const { reducer } = passengersSlice
