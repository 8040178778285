import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PassengerTypes } from 'interfaces/Passenger'
import { SearchValues } from 'interfaces/SearchValues'
import { MultiTicket } from 'interfaces/Ticket'
import { fetchTicketsSearch } from 'store/features/ticketsSearch/actions'

export type SearchState = SearchValues & { isSingle: boolean }

export interface TicketsSearchState {
  searchValues: SearchState
  entities: Array<MultiTicket>
  isLoading: boolean
  isError: boolean
}

const initialSearchValues: SearchState = {
  isSingle: true,
  origin: { name: '', uic: '', synonyms: [] as Array<string>, shortCode: '' },
  destination: { name: '', uic: '', synonyms: [] as Array<string>, shortCode: '' },
  outboundDate: new Date().toISOString(),
  inboundDate: null,
  passengers: {
    [PassengerTypes.BASIC_MALE]: 1,
    [PassengerTypes.BASIC_FEMALE]: 0,
    [PassengerTypes.KID]: 0,
    [PassengerTypes.KID_BABY]: 0,
    [PassengerTypes.DISABLED_MALE]: 0,
    [PassengerTypes.DISABLED_FEMALE]: 0,
  },
}

export const initialState: TicketsSearchState = {
  searchValues: initialSearchValues,
  entities: [],
  isLoading: true,
  isError: false,
}

export const ticketsSearchSlice = createSlice({
  name: 'ticketsSearch',
  initialState,
  reducers: {
    setSearchInitialState: (state, action: PayloadAction<Omit<SearchState, 'isInitial'>>) => ({
      ...state,
      searchValues: {
        ...action.payload,
      },
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchTicketsSearch.pending, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))

    builder.addCase(fetchTicketsSearch.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      isError: false,
      entities: action.payload,
    }))

    builder.addCase(fetchTicketsSearch.rejected, state => ({
      ...state,
      isLoading: false,
      isError: true,
      entities: [],
    }))
  },
})

export const { setSearchInitialState } = ticketsSearchSlice.actions
export { fetchTicketsSearch }
export const { reducer } = ticketsSearchSlice
