import { createSelector } from '@reduxjs/toolkit'
import { CarriageLayoutType, CarriageSeatWithCarriage } from 'interfaces/Carriage'
import { ProductCode, seatSelectionProductCodes } from 'interfaces/Codes'
import { Passenger } from 'interfaces/Passenger'
import { Ticket } from 'interfaces/Ticket'
import { RootState } from 'store/core'
import { getAllCarriages } from 'store/features/carriages/selectors'

import { getCheapestInventoryClass } from './utilities/getCheapestInventoryClass'
import { getSeatPrice } from './utilities/getSeatPrice'

const getState = (state: RootState) => state.passengers

const getPassengerStateByType = createSelector(
  getState,
  passengers => passengers[passengers.currentStepType]
)

export const getSelectedTicket = createSelector(getState, ({ ticket }) => ticket)

export const getCurrentStepType = createSelector(getState, ({ currentStepType }) => currentStepType)

export const getSelectedTicketByCurrentType = createSelector(
  getSelectedTicket,
  getCurrentStepType,
  (ticket, currentStepType) => ticket?.[currentStepType] as Ticket
)

export const getSecondTicketByCurrentType = createSelector(
  getSelectedTicket,
  getCurrentStepType,
  (ticket, currentStepType) => {
    if (!ticket) return null
    if (currentStepType === 'outbound') return ticket.inbound
    return ticket.outbound
  }
)

export const getPassengers = createSelector(getPassengerStateByType, ({ passengers }) => passengers)

export const getSeats = createSelector(getPassengerStateByType, ({ seats }) => seats)

export const getPassengersCount = createSelector(
  getPassengerStateByType,
  ({ passengers }) => passengers.length
)

export const getBedding = createSelector(getPassengerStateByType, ({ bedding }) => bedding)

export const getFilter = createSelector(getPassengerStateByType, ({ filter }) => filter)

export const getManualAllocation = createSelector(
  getPassengerStateByType,
  ({ manualAllocation }) => manualAllocation
)

export const getDiscountSeatsAvailability = createSelector(
  getPassengerStateByType,
  ({ discountSeatsAvailability }) => discountSeatsAvailability
)

export const getPassengerToSelect = createSelector(getPassengers, getSeats, (passengers, seats) =>
  passengers.find(passenger => !seats[passenger.id])
)

export const getManualAllocationDisabled = createSelector(
  getDiscountSeatsAvailability,
  ({ coupe, platzkart }) => !coupe && !platzkart
)

export const getAllPassengersSeatsSelected = createSelector(
  getManualAllocation,
  getPassengers,
  getSeats,
  (useManualAllocation, passengers, seats) =>
    !useManualAllocation || passengers.length === Object.keys(seats).length
)

export const getAtLeastOnePassengerSeatSelected = createSelector(
  getManualAllocation,
  getSeats,
  (useManualAllocation, seats) => !useManualAllocation || Object.keys(seats).length >= 1
)

const calculatePrice = (
  state: RootState,
  seats: Record<string, CarriageSeatWithCarriage>,
  useManualAllocation: boolean,
  useBedding: boolean,
  ticket: Ticket | null | undefined,
  filter: CarriageLayoutType | null
) => {
  const passengers = getPassengers(state)

  if (!ticket) return 0

  if (!useManualAllocation) {
    const cheapestInventoryClass = getCheapestInventoryClass(ticket.inventoryClasses, filter)
    const isCoupe = filter === 'coupe'

    const calculatedPrice = cheapestInventoryClass.items.reduce((acc, inventoryItem) => {
      if (inventoryItem.productCode === ProductCode.BEDLIN && !isCoupe && !useBedding) return acc
      return acc + inventoryItem.passengerFares.reduce((acc2, curr) => acc2 + curr.price, 0)
    }, 0)

    return calculatedPrice
  }

  return Object.entries(seats).reduce<number>((acc, [passengerId, seat]) => {
    const seatCarriage = getAllCarriages(state).find(
      carriage => carriage.carriageNumber === seat.carriage
    )
    const isCoupeSeat = Boolean(seatCarriage?.isCoupe)

    const passenger = passengers.find(p => p.id === passengerId)
    if (!passenger) return acc
    return acc + getSeatPrice(ticket, seat, passenger, [], useBedding, isCoupeSeat)
  }, 0)
}

export const getOutboundPrice = (state: RootState) => {
  const { outbound, ticket } = state.passengers
  const { seats, filter, passengers, manualAllocation, bedding } = outbound
  const price = calculatePrice(state, seats, manualAllocation, bedding, ticket?.outbound, filter)
  const outboundTicket = ticket?.outbound

  if (!price && outboundTicket) {
    const priceForAutoAllocatedTicket =
      filter === 'coupe' ? outboundTicket.coupeMinimalPrice : outboundTicket.platzkartMinimalPrice
    return priceForAutoAllocatedTicket * passengers.length
  }
  return price
}

export const getInboundPrice = (state: RootState) => {
  const { inbound, ticket } = state.passengers
  const { seats, filter, manualAllocation, bedding } = inbound

  return calculatePrice(state, seats, manualAllocation, bedding, ticket?.inbound, filter)
}

export const getTotalPrice = createSelector(
  (state: RootState) => state,
  state => {
    const { currentStepType } = state.passengers
    const outbound = getOutboundPrice(state)
    const inbound = getInboundPrice(state)

    return currentStepType === 'outbound' ? outbound : outbound + inbound
  }
)

export const calculateSeatSelectionPrice = (
  seats: Record<string, CarriageSeatWithCarriage>,
  passengers: Passenger[],
  ticket: Ticket,
  manualAllocation: boolean
) => {
  if (!manualAllocation || !ticket) return 0

  return Object.entries(seats).reduce<number>((acc, [passengerId, seat]) => {
    const passenger = passengers.find(p => p.id === passengerId)
    if (!passenger) return acc

    const faresToIgnore = Object.values(ProductCode).filter(
      code => !seatSelectionProductCodes.includes(code)
    )

    const selectionPrice = getSeatPrice(ticket, seat, passenger, faresToIgnore)
    return selectionPrice + acc
  }, 0)
}

export const getSeatSelectionPrice = createSelector(
  getManualAllocation,
  getSeats,
  getPassengers,
  getSelectedTicketByCurrentType,
  (useManualAllocation, seats, passengers, ticket) =>
    calculateSeatSelectionPrice(seats, passengers, ticket, useManualAllocation)
)

export const getBookingCreationState = createSelector(
  getState,
  ({ bookingCreationState }) => bookingCreationState
)

export const getHighlightAllocationCheckbox = createSelector(
  getState,
  ({ highlightAllocationCheckbox }) => highlightAllocationCheckbox
)
