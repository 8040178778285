import 'react-toastify/dist/ReactToastify.css'

import { Icon } from 'components/ui/Icon'
import { ToastType } from 'interfaces/Toast'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { ToastContainer } from 'react-toastify'
import { useMount } from 'react-use'
import { useTheme } from 'styled-components'

import { Wrapper } from './styles'
import { toastByType } from './utils/toastByType'

export const Toast: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const router = useRouter()

  const checkRouterToast = () => {
    if (typeof router.query.toast === 'undefined') return
    toastByType(Number(router.query.toast) as ToastType, t)
  }

  useMount(() => {
    checkRouterToast()
  })

  const CustomToastIcon = () => (
    <Icon icon={theme.icons.stepper.PassengerData} size={30} color={theme.palette.secondary.MAIN} />
  )

  return (
    <Wrapper>
      <ToastContainer
        position='top-center'
        autoClose={7000}
        limit={1}
        draggable
        pauseOnHover={false}
        icon={CustomToastIcon}
      />
    </Wrapper>
  )
}
