export enum PassengerTypes {
  BASIC_MALE = 'A', // basic male
  BASIC_FEMALE = 'F', // basic female
  KID = 'B', // kid 7-15
  KID_BABY = 'C', // kid 0-7
  DISABLED_FEMALE = 'WC_F', // disabled female
  DISABLED_MALE = 'WC_M', // disabled male
}

export interface Passenger {
  type: PassengerTypes
  id: string
  disabilityType: string
}
