import { DocumentType } from 'components/pages/new/BookingPrint/libs/ActionsBlock'
import { API_URL } from 'core/env'
import { PaymentGateway } from 'interfaces/Payment'

export const URLS = {
  stations: `${API_URL}/stations`,
  routeStations: `${API_URL}/route_stations`,
  dayprice: `${API_URL}/calendar`,
  tickets: `${API_URL}/search`,
  service: `${API_URL}/service`,
  createBooking: `${API_URL}/booking/`,
  getBooking: (bookingId: string) => `${API_URL}/booking/${bookingId}`,
  updateBooking: (bookingId: string) => `${API_URL}/booking/${bookingId}`,
  cancelBooking: (bookingId: string) => `${API_URL}/booking/${bookingId}`,
  payBooking: (gateway: PaymentGateway, bookingId: string) =>
    `${API_URL}/booking/${bookingId}/${gateway}/pay`,
  checkOrder: (gateway: PaymentGateway, orderId: string) =>
    `${API_URL}/booking/pay/${gateway}/${orderId}`,
  checkTicket: (bookingId: string) => `${API_URL}/booking/${bookingId}/tickets/ready`,
  getInternalProducts: `${API_URL}/booking/internal_products`,
  getOTPCode: `${API_URL}/booking/otp`,
  authWithOTP: `${API_URL}/booking/auth`,
  refreshToken: `${API_URL}/booking/refresh`,
  getBookingHistory: `${API_URL}/profile/bookings`,
  getAuthenticatedBooking: (bookingId: string) => `${API_URL}/profile/bookings/${bookingId}`,
  refundBooking: (bookingId: string) => `${API_URL}/profile/bookings/${bookingId}/refund`,
  sendTicketsToEmail: (bookingId: string, documentType: DocumentType) =>
    `${API_URL}/profile/bookings/${bookingId}/email/${documentType}`,
  getRoutesPrices: `${API_URL}/prices`,
  getSchedule: (serviceId: string) => `${API_URL}/service/${serviceId}/schedule`,
  clearBookingPayment: (bookingId: string) => `${API_URL}/booking/${bookingId}/clear`,
  bookingStatus: (bookingId: string) => `${API_URL}/booking/${bookingId}/status`,
  kaspi: 'https://kaspi.kz/online',
  checkToken: `${API_URL}/profile/check_token`,
}
