import authAxios from 'api/axios/authAxios'
import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { SERVICE_TOKEN } from 'core/env'
import { Booking } from 'interfaces/Booking'
import { ProjectLocale } from 'interfaces/ProjectLocale'
import { getLanguageHeaderByLocale } from 'utilities/getLanguageHeaderByLocale'

export const getBooking = async (
  bookingId: string,
  isAuth = false,
  locale?: ProjectLocale,
  includeInactive?: boolean
): Promise<Booking> => {
  const axios = isAuth ? authAxios : baseAxios
  const url = isAuth ? URLS.getAuthenticatedBooking(bookingId) : URLS.getBooking(bookingId)
  const config = locale && {
    headers: {
      'Accept-Language': getLanguageHeaderByLocale(locale),
      'Authorization-Service': `Bearer ${SERVICE_TOKEN}`,
    },
    params: {
      include_inactive: Boolean(includeInactive),
    },
  }
  const { data } = await axios.get<Booking>(url, config || {})
  return data
}
