import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentGateway } from 'interfaces/Payment'

export interface Gateway {
  gateway: PaymentGateway
}

export const initialState: Gateway = {
  gateway: PaymentGateway.Kaspi,
}

export const gatewaySlice = createSlice({
  name: 'gateway',
  initialState,
  reducers: {
    setGateway: (state, action: PayloadAction<PaymentGateway>) => ({
      ...state,
      gateway: action.payload,
    }),
  },
})

export const { setGateway } = gatewaySlice.actions
export const { reducer } = gatewaySlice
