import styled, { css } from 'styled-components'
import { indent } from 'styles/mixins'
import { makeElementSize } from 'utilities/makeElementSize'

import { Props } from './props'

type IndentType = 'margin' | 'padding'

const getIndent = (
  type: IndentType,
  { total = 0, right = 0, top = 0, left = 0, bottom = 0, vertical = 0, horizontal = 0 }
) => {
  if (total) return indent(type, total)
  if (vertical || horizontal) return indent(type, { vertical, horizontal })
  if (top || left || right || bottom)
    return indent(type, {
      top,
      left,
      right,
      bottom,
    })

  return ''
}

export const Wrapper = styled.div<Props>`
  display: ${({ display }) => display || 'block'};
  width: ${({ width }) => makeElementSize(width)};
  height: ${({ height }) => makeElementSize(height)};
  ${props => css`
    ${getIndent('padding', {
      total: props.padding,
      right: props.paddingRight,
      left: props.paddingLeft,
      top: props.paddingTop,
      bottom: props.paddingBottom,
      vertical: props.paddingVertical,
      horizontal: props.paddingHorizontal,
    })}

    ${getIndent('margin', {
      total: props.margin,
      right: props.marginRight,
      left: props.marginLeft,
      top: props.marginTop,
      bottom: props.marginBottom,
      vertical: props.marginVertical,
      horizontal: props.marginHorizontal,
    })}
  `}
`
