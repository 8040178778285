import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`

export const Menu = styled.ul<{ open: boolean }>`
  position: absolute;
  left: -16px;
  top: calc(100% + 16px);
  background-color: ${({ theme }) => theme.palette.core.WHITE};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 0;
  list-style: none;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;

  ${({ open }) =>
    open &&
    `
    opacity: 1;
    pointer-events: auto;
  `}
`

export const MenuItem = styled.li`
  background-color: transparent;
  padding: 8px 16px;
  white-space: nowrap;
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
  }
`
