import { Footer } from 'components/shared/Footer'
import { Header } from 'components/shared/Header'
import { Toast } from 'components/shared/Toast'
import { FC } from 'react'

import { Wrapper } from './styles'

export const PageLayout: FC = ({ children }) => (
  <Wrapper>
    <Toast />
    <Header />
    {children}
    <Footer />
  </Wrapper>
)
