import { HeaderLink } from 'components/shared/Header/types'
import { Typography } from 'components/ui/Typography'
import Link from 'next/link'
import { FC } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useToggle } from 'react-use'
import { useTheme } from 'styled-components'

import { Menu, MenuItem, Wrapper } from './styles'

interface Props {
  text: string
  links: Array<HeaderLink>
}

export const DesktopDropdown: FC<Props> = ({ text, links }) => {
  const theme = useTheme()
  const [isOpen, toggleIsOpen] = useToggle(false)

  const closeDropdown = () => {
    toggleIsOpen(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={closeDropdown}>
      <Wrapper style={{ position: 'relative' }} data-testid='desktop-dropdown'>
        <Typography
          color={theme.palette.text.ALTERNATIVE}
          typography={theme.typography.variants.content.BOLD}
          onClick={toggleIsOpen}
        >
          {text}
        </Typography>
        <Menu open={isOpen} data-testid='desktop-dropdown-menu'>
          {links.map(({ url, text: title }) => (
            <MenuItem key={title}>
              <Link href={url}>
                <a href={url} onClick={closeDropdown}>
                  <Typography color={theme.palette.text.DEEP_GRAY}>{title}</Typography>
                </a>
              </Link>
            </MenuItem>
          ))}
        </Menu>
      </Wrapper>
    </OutsideClickHandler>
  )
}
