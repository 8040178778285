export enum ProductCode {
  FEE_50 = 'FEE_50', // 50% cancellation fee between 24h and 6h before departure
  CANCELLATION_FEE = 'CANCELLATION_FEE', // 70% Cancellation Fee (not working)
  BEDLIN = 'BEDLIN', // Bedlinen
  BEDLINEN_COMPARTMENT = 'BEDLINEN_COMPARTMENT', // Bedlinen: Private Compartment
  CANCELLATION_70 = 'CANCELLATION_70', // Cancellation fee 70% of platzkart
  COUPE_DSC = 'COUPE_DSC', // Coupe: Discount Platzcart
  COUPE_TK_DSC = 'COUPE_TK_DSC', // Coupe: Discount Ticket
  COUPE_PREM = 'COUPE_PREM', // Coupe: Premium Platzcart
  COUPE_TK_PREM = 'COUPE_TK_PREM', // Coupe: Premium Ticket
  COUPE_PRIVATE_FEE = 'COUPE_PRIVATE_FEE', // Coupe: Private Compartment Fee
  COUPE = 'COUPE', // Coupe: Standard Platzcart
  PLATZPL_CO = 'PLATZPL_CO', // Coupe: Standard Ticket
  FEE_100_CP = 'FEE_100_CP', // Non-Refundable Coupe Premium Seat Fee
  FEE_100_CS = 'FEE_100_CS', // Non-Refundable Coupe Standard Seat Fee
  FEE_100_PP = 'FEE_100_PP', // Non-Refundable Platzkart Premium Seat Fee
  FEE_100 = 'FEE_100', // Non-Refundable Platzkart Standard Seat Fee
  PLATZKART_DSC = 'PLATZKART_DSC', // Platzcart: Discount Platzcart
  PLATZKART_PREM = 'PLATZKART_PREM', // Platzcart: Premium Platzcart
  PLATZKART = 'PLATZKART', // Platzcart: Standard Platzcart
  PLATZST_DSC = 'PLATZST_DSC', // Platzkart: Discount Ticket
  PLATSZT_PREM = 'PLATSZT_PREM', // Platzkart: Premium Ticket
  PLATZST = 'PLATZST', // Platzkart: Standard Ticket
  FEE_PREMIUM_SEAT = 'FEE_PREMIUM_SEAT', // Seat Selection Fee: Premium Coupe Seat
  FEE_PREMIUM_SEAT_PL = 'FEE_PREMIUM_SEAT_PL', // Seat Selection Fee: Premium Platzkart Seat
  FEE_STANDARD_SEAT = 'FEE_STANDARD_SEAT', // Seat Selection Fee: Standard Coupe Seat
  FEE_STANDARD_PL = 'FEE_STANDARD_PL', // Seat Selection Fee: Standard Platzkart Seat
  FEE_DISCOUNT_SEAT = 'FEE_DISCOUNT_SEAT', // Seat Selection Fee: Discount Seat
  FEE_DISCOUNT_PL = 'FEE_DISCOUNT_PL', // Seat Selection Fee: Discount Seat
}

export const seatSelectionProductCodes: Array<ProductCode> = [
  ProductCode.FEE_PREMIUM_SEAT,
  ProductCode.FEE_PREMIUM_SEAT_PL,
  ProductCode.FEE_STANDARD_SEAT,
  ProductCode.FEE_STANDARD_PL,
  ProductCode.FEE_DISCOUNT_SEAT,
  ProductCode.FEE_DISCOUNT_PL,
]

export enum ProductType {
  FE = 'FE', // Fee
  ET = 'ET', // External ticket
  ST = 'ST', // Ticket including seat reservation
  SF = 'SF', // Seat selection
}

export enum TariffCode {
  BEDLIN_1 = 'BEDLIN_1', // Bedlinen
  CANCELLATION_FEE = 'CANCELLATION_FEE', // Cancellation Fee
  COUPE_2K01 = 'COUPE_2K01', // COUPE_2K01_DISCOUNT
  COUPE_2K01_MIN6 = 'COUPE_2K01_MIN6', // COUPE_2K01_DISCOUNT_nobedlinen
  COUPE_2K02_MIN6_STAN = 'COUPE_2K02_MIN6_STAN', // COUPE_2K02_STANDARD_nobedlinen
  COUPE_2K02_STANDARD = 'COUPE_2K02_STANDARD', // COUPE_2K02_STANDARD
  COUPE_2K03_MIN6_PREM = 'COUPE_2K03_MIN6_PREM', // COUPE_2K03_PREMIUM_nobedlinen
  COUPE_2K03_PREMIUM = 'COUPE_2K03_PREMIUM', // COUPE_2K03_PREMIUM
  COUPE_SEAT_DISCOUNT = 'COUPE_SEAT_DISCOUNT', // Coupe ticket DISCOUNT
  COUPE_SEAT_PREMIUM = 'COUPE_SEAT_PREMIUM', // Coupe ticket PREMIUM
  COUPE_SEAT_STANDARD = 'COUPE_SEAT_STANDARD', // Coupe ticket STANDARD
  FEE_100_PC_NOREF = 'FEE_100_PC_NOREF', // Fee: Non Refundable Coupe Premium Seat Fee
  FEE_100_PP_NOREF = 'FEE_100_PP_NOREF', // Fee: Non Refundable Platzkart Premium Seat Fee
  FEE_100_SC_NOREF = 'FEE_100_SC_NOREF', // Fee: Non Refundable Coupe Standard Seat Fee
  FEE_100_SP_NOREFUND = 'FEE_100_SP_NOREFUND', // Fee: Non Refundable Platzkart Standard Seat Fee
  FEE_50 = 'FEE_50', // Fee 50%
  FEE_70 = 'FEE_70', // Fee: Temporary 70% fee cancellation
  FEE_PREMIUM = 'FEE_PREMIUM', // Fee: Premium Seat Coupe
  FEE_PREMIUM_PLATZ = 'FEE_PREMIUM_PLATZ', // Fee: Premium Seat Platzkart
  FEE_STANDARD = 'FEE_STANDARD', // Fee: Standard Seat Coupe
  FEE_STANDARD_PLATZ = 'FEE_STANDARD_PLATZ', // Fee: Standard Seat Platzkart
  FEE_DISC = 'FEE_DISC', // Fee: Discount Seat
  FEE_DISC_PLATZ = 'FEE_DISC_PLATZ', // Fee: Discount Seat Platzkart
  PLATZKART_3P04 = 'PLATZKART_3P04', // Platzkart 3P04 BASE DISCOUNT
  PLATZKART_3P04_MIN6 = 'PLATZKART_3P04_MIN6', // Platzkart 3P04 BASE DISCOUNT no bedlinen
  PLATZKART_3P05_MIN_S = 'PLATZKART_3P05_MIN_S', // PLATZKART_3P05_STANDARD_nobedlinen
  PLATZKART_3P05_STAN = 'PLATZKART_3P05_STAN', // PLATZKART_3P05_STANDARD
  PLATZKART_3P06_MIN_P = 'PLATZKART_3P06_MIN_P', // PLATZKART_3P06_PREMIUM_nobedlinen
  PLATZKART_3P06_PREM = 'PLATZKART_3P06_PREM', // PLATZKART_3P06_PREM
  PLATZKART_SEAT_DISC = 'PLATZKART_SEAT_DISC', // Platzkart (ticket) Discount
  PLATZKART_SEAT_PREM = 'PLATZKART_SEAT_PREM', // Platzkart (ticket) Premium
  PLATZKART_SEAT_STAND = 'PLATZKART_SEAT_STAND', // Platzkart (ticket) Standard
}

export const seatSelectionTariffCodes: Array<TariffCode> = [
  TariffCode.FEE_PREMIUM,
  TariffCode.FEE_PREMIUM_PLATZ,
  TariffCode.FEE_STANDARD,
  TariffCode.FEE_STANDARD_PLATZ,
  TariffCode.FEE_DISC,
  TariffCode.FEE_DISC_PLATZ,
]

export enum ProductFamilyId {
  COUPE_PRIVATE = 'COUPE_PRIVATE', // Coupe Private Compartment
  PLATZKART_PRIVATE = 'PLATZKART_PRIVATE', // Platzkart Private Compartment
  TICKET_CP_DSC = 'TICKET_CP_DSC', // Coupe Discount
  TICKET_CP_PREM = 'TICKET_CP_PREM', // Coupe Premium
  TICKET_CP_STAND = 'TICKET_CP_STAND', // Coupe Standard
  TICKET_PL_DSC = 'TICKET_PL_DSC', // Platzkart Discount
  TICKET_PL_PREM = 'TICKET_PL_PREM', // Platzkart Premium
  TICKET_PL_STAND = 'TICKET_PL_STAND', // Platzkart Standard
}

export enum SeatPropertyCode {
  DISCOUNT = 'DISCOUNT', // Discount Seat
  IDR = 'IDR', // Inbound Direction
  LOWER = 'LOWER', // Lower Bed
  LOWERPL = 'LOWERPL', // Lower Bed
  TOILET = 'TOILET', // Near Toilet
  NOTOILET = 'NOTOILET', // Not Near Toilet
  ODR = 'ODR', // Outbound Direction
  PREMIUM = 'PREMIUM', // Premium Seat Coupe
  PREMIUMPL = 'PREMIUMPL', // Premium Seat Platzkart
  PREMIUMPLF = 'PREMIUMPLF', // Premium Seat Platzkart Fee
  SIDE = 'SIDE', // Side bed
  STANDARD = 'STANDARD', // Standard Seat
  UPPER = 'UPPER', // Upper Bed
  UPPERPL = 'UPPERPL', // Upper Bed
}
