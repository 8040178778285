import { createSlice } from '@reduxjs/toolkit'
import { Carriage } from 'interfaces/Carriage'
import { fetchCarriagesList } from 'store/features/carriages/actions'

export interface CarriagesState {
  entities: Array<Carriage>
  isLoading: boolean
  isError: boolean
}

const initialState: CarriagesState = {
  entities: [],
  isLoading: true,
  isError: false,
}

export const carriagesSlice = createSlice({
  name: 'carriages',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCarriagesList.pending, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))

    builder.addCase(fetchCarriagesList.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      isError: false,
      entities: action.payload,
    }))

    builder.addCase(fetchCarriagesList.rejected, () => ({
      isLoading: false,
      isError: true,
      entities: [],
    }))
  },
})

export { fetchCarriagesList }
export const { reducer } = carriagesSlice
