import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const usePersistLocale = () => {
  const { locale, defaultLocale } = useRouter()

  const saveLocale = () => {
    if (!locale) return

    const date = new Date()
    const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
    date.setTime(date.getTime() + expireMs)
    document.cookie = `NEXT_LOCALE=${locale};expires=${date.toUTCString()};path=/`
  }

  useEffect(saveLocale, [locale, defaultLocale])
}
