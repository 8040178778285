import { Typography } from 'components/ui/Typography'
import Link, { LinkProps } from 'next/link'
import { FC, HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { Wrapper } from './styles'

interface Props {
  isActive?: boolean
  clickHandler?: () => void
}

export const NavLink: FC<LinkProps & HTMLAttributes<HTMLAnchorElement> & Props> = ({
  children,
  title,
  href,
  isActive,
  clickHandler,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <Wrapper isActive={isActive} data-testid='nav-link'>
      <Typography
        color={theme.palette.text.ALTERNATIVE}
        typography={theme.typography.variants.content.BOLD}
      >
        <Link href={href} {...rest}>
          <a title={title} href={href as string} onClick={() => clickHandler?.()}>
            {children}
          </a>
        </Link>
      </Typography>
    </Wrapper>
  )
}
