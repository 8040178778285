module.exports = {
  locales: ['ru', 'kk'],
  defaultLocale: 'ru',
  loader: false,
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  loadLocaleFrom: (lang, ns) => import(`./locales/${lang}/${ns}.json`).then(m => m.default),
  pages: {
    '*': [
      'core',
      'home',
      'header',
      'footer',
      'datepicker',
      'ticketSearch',
      'radio',
      'stepper',
      'passengersPicker',
      'payment',
      'unfinishedBooking',
      'toasts',
      'meta',
      'error',
      'news',
      'documents',
      'kaspi',
    ],
    '/': ['returnPrintForm'],
    '/[route]': ['contacts', 'trainSchedule', 'staticPages', 'landing'],
    '/search/results': ['carriage', 'selectTrain', 'passengerData', 'trainSchedule'],
    '/booking/[bookingId]/passengers': ['passengerData', 'passengerDataForm', 'documents'],
    '/otp/[type]': ['otp'],
    '/publichnaya-oferta': ['staticPages'],
    '/politika-konfidencialnosti': ['staticPages'],
    '/kontakty': ['staticPages', 'contacts'],
    '/covid': ['staticPages'],
    '/faq': ['staticPages'],
    '/napravleniya': ['staticPages'],
    '/payment': ['staticPages'],
    '/booking/old-history': [
      'otp',
      'printRefund',
      'documents',
      'passengerDataForm',
      'passengerData',
    ],
    '/booking/history': ['bookingHistory', 'bookingRefund'],
    '/booking/history/[bookingId]/print': ['bookingHistory', 'bookingPrint'],
    '/booking/history/[bookingId]/refund': ['bookingHistory', 'bookingRefund', 'passengerData'],
    '/booking/old-history/[bookingId]/refund': [
      'otp',
      'printRefund',
      'documents',
      'passengerDataForm',
      'passengerData',
    ],
    '/booking/old-history/[bookingId]/print': [
      'otp',
      'printRefund',
      'documents',
      'passengerDataForm',
      'passengerData',
    ],
  },
}
