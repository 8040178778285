import { createSlice } from '@reduxjs/toolkit'
import { Kaspi, Paybox } from 'interfaces/Payment'
import { bookingPaymentAction } from 'store/features/bookingPayment/actions'

export interface BookingPaymentState {
  isLoading: boolean
  isError: boolean
  error: string | null
  paymentData: Paybox | Kaspi | null
}

export const initialState: BookingPaymentState = {
  isLoading: false,
  isError: false,
  error: null,
  paymentData: null,
}

export const bookingPaymentSlice = createSlice({
  name: 'bookingPayment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(bookingPaymentAction.pending, state => ({
      ...state,
      isLoading: true,
      isError: false,
      error: null,
    }))

    builder.addCase(bookingPaymentAction.fulfilled, (state, action) => ({
      ...state,
      paymentData: action.payload,
      isLoading: false,
      isError: false,
      error: null,
    }))

    builder.addCase(bookingPaymentAction.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      error: action.payload as string,
    }))
  },
})

export const { reducer } = bookingPaymentSlice
