import { pushEvent } from 'analytics/push'
import camelize from 'camelize-ts'
import { format } from 'date-fns'
import { SuccessBookingPassengerProduct } from 'interfaces/Booking'
import { seatSelectionProductCodes } from 'interfaces/Codes'
import { AnalyticsEventType, ECommerceEvent, SelectSeatEventData } from 'interfaces/GoogleAnalytics'
import { Passenger } from 'interfaces/Passenger'
import { Ticket } from 'interfaces/Ticket'
import { getPassengersCount } from 'store/features/passengers/utilities/getPassengersCount'

const buildECommerceProduct = (
  passenger: Passenger,
  seat: Array<SuccessBookingPassengerProduct>,
  journey: Ticket,
  bookingNumber: string
) => {
  const { arrivalStationName, departureStationName, departureDateTime, arrivalDateTime } = journey
  const mainSeatItem = seat.find(({ seat: seatNumber }) => Boolean(seatNumber))
  const price = seat.reduce((acc, seatItem) => {
    if (!seatSelectionProductCodes.includes(seatItem.code)) {
      return acc + seatItem.price
    }
    return acc
  }, 0)

  return {
    passengerId: passenger.id,
    name: `${departureStationName} - ${arrivalStationName}`,
    category: 'trainTickets',
    variant: mainSeatItem?.name,
    price,
    quantity: 1,
    dimension2: departureStationName,
    dimension3: arrivalStationName,
    dimension4: format(new Date(departureDateTime), 'dd-MM-yyyy'),
    dimension5: format(new Date(arrivalDateTime), 'dd-MM-yyyy'),
    dimension6: bookingNumber,
    dimension7: mainSeatItem?.carriage,
    dimension8: mainSeatItem?.seat,
  }
}

export const selectSeatsEvent = (data: SelectSeatEventData, bookingNumber: string) => {
  const { serviceIdentifier, serviceName, serviceType, travelDuration } = data.ticket

  const products = data.passengers.map(passenger => {
    const { id } = passenger
    const transformedId = camelize(id)
    return buildECommerceProduct(passenger, data.seats[transformedId], data.ticket, bookingNumber)
  })

  const ticketProducts = getPassengersCount(data.passengers)

  pushEvent({
    event: AnalyticsEventType.RESULTS,
    routeType: data.type,
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        actionField: { list: 'seat selected' },
        products,
      },
    },
    extraServices: {
      seatAvailability: Boolean(data.seatSelectionPrice),
      seatPrice: data.seatSelectionPrice,
    },
    ticketProducts,
    journeyProperties: {
      serviceIdentifier,
      serviceName,
      serviceType,
      travelDuration,
    },
    eventProperties: {
      eventCategory: 'Enhanced Ecommerce',
      eventAction: 'seat selected',
      eventLabel: 'seat tab',
    },
  } as ECommerceEvent)
}
