import styled from 'styled-components'

export const Wrapper = styled.div`
  @media screen and (max-width: 1180px) {
    display: none;
  }
`

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
  width: 1px;
  height: 17px;
`
