import { createAsyncThunk } from '@reduxjs/toolkit'
import { getBooking } from 'api/requests/getBooking'
import { ProjectLocale } from 'interfaces/ProjectLocale'

interface Props {
  bookingId: string
  isAuth: boolean
  locale?: ProjectLocale
}

export const getBookingAction = createAsyncThunk(
  'fetchBooking',
  async ({ bookingId, isAuth, locale }: Props) => {
    const response = await getBooking(bookingId, isAuth, locale)
    return response
  }
)
