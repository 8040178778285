import { createSlice } from '@reduxjs/toolkit'
import { Calendar } from 'interfaces/Calendar'
import { fetchPricesCalendar } from 'store/features/pricesCalendar/actions'

export interface PricesCalendarState extends Calendar {
  isLoading: boolean
  isError: boolean
}

const initialState: PricesCalendarState = {
  inbound: [],
  outbound: [],
  isLoading: true,
  isError: false,
}

export const pricesCalendarSlice = createSlice({
  name: 'pricesCalendar',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPricesCalendar.pending, () => ({
      ...initialState,
      isLoading: true,
      isError: false,
    }))

    builder.addCase(fetchPricesCalendar.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
      isError: false,
    }))

    builder.addCase(fetchPricesCalendar.rejected, state => ({
      ...state,
      isLoading: false,
      isError: true,
    }))
  },
})

export { fetchPricesCalendar }
export const { reducer } = pricesCalendarSlice
