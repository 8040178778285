import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { withParams } from 'api/utilities/withParams'
import { format } from 'date-fns'
import { Service } from 'interfaces/Service'

interface Params {
  serviceName: string
  date: Date
  origin: string
  destination: string
}

export const getService = async ({
  serviceName,
  date,
  origin,
  destination,
}: Params): Promise<Service> => {
  const { data } = await baseAxios.get<Service>(
    withParams(URLS.service, {
      service_name: serviceName,
      date: format(date, 'yyyy-MM-dd'),
      origin,
      destination,
    })
  )
  return data
}
