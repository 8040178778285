import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { TokensResponse } from 'interfaces/Auth'

export const refreshToken = async (token: string) => {
  if (!token) {
    throw new Error('Refresh is impossible')
  }

  const { data } = await baseAxios.get<TokensResponse>(URLS.refreshToken, {
    params: {
      token,
    },
  })

  return data
}
