import { HeaderLink } from 'components/shared/Header/types'
import { Box } from 'components/ui/Box'
import { Icon } from 'components/ui/Icon'
import { Typography } from 'components/ui/Typography'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'
import { Collapse } from 'react-collapse'
import { useToggle } from 'react-use'
import { useTheme } from 'styled-components'

import { IconWrapper, Title, Wrapper } from './styles'

interface Props {
  text: string
  links: Array<HeaderLink>
  onClose: () => void
}

export const MobileDropdown: FC<Props> = ({ text, links, onClose }) => {
  const theme = useTheme()
  const router = useRouter()
  const [isOpen, toggleIsOpen] = useToggle(false)

  const isActive = useMemo(() => links.some(link => router.asPath === link.url), [router])

  return (
    <Wrapper isOpened={isOpen}>
      <Title onClick={toggleIsOpen} isActive={isActive}>
        <Typography
          color={theme.palette.text.ALTERNATIVE}
          typography={theme.typography.variants.content.BOLD}
        >
          {text}
        </Typography>
        <IconWrapper isOpened={isOpen}>
          <Icon
            color={theme.palette.text.ALTERNATIVE}
            icon={theme.icons.core.ArrowShow}
            size={11}
          />
        </IconWrapper>
      </Title>
      <Collapse isOpened={isOpen}>
        {links.map(({ url, text: title }) => (
          <Box paddingTop={16} key={title}>
            <Link href={url}>
              <a title={title} href={url} onClick={onClose}>
                <Typography color={theme.palette.text.ALTERNATIVE}>{title}</Typography>
              </a>
            </Link>
          </Box>
        ))}
      </Collapse>
    </Wrapper>
  )
}
