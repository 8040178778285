import { FC, HTMLAttributes } from 'react'
import { AnyColor, palette } from 'styles/theme/palette'
import { AnyTypography, typography } from 'styles/theme/typography'

import { Wrapper } from './styles'

type AsTags = 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'a'

interface Props extends HTMLAttributes<HTMLElement> {
  lh?: number | string
  typography?: AnyTypography
  as?: AsTags
  color?: AnyColor
}

const getColorByTypography = (t: AnyTypography, color?: AnyColor): AnyColor => {
  if (color) return color

  switch (t) {
    case typography.Button.LIGHT:
    case typography.Description.MAIN:
    case typography.Description.BOLD:
      return palette.Text.DEEP_GRAY
    case typography.Button.MAIN:
      return palette.Text.ALTERNATIVE
    default:
      return palette.Text.MAIN
  }
}

export const Typography: FC<Props> = ({
  typography: t = typography.Content.MAIN,
  as = 'span',
  color,
  children,
  ...props
}) => (
  <Wrapper as={as} color={getColorByTypography(t, color)} typography={t} {...props}>
    {children}
  </Wrapper>
)
