import { LanguageSelector } from 'components/shared/Header/libs/LanguageSelector'
import { MobileDropdown } from 'components/shared/Header/libs/MobileDropdown'
import { NavLink } from 'components/shared/Header/libs/NavLink'
import { Phone } from 'components/shared/Header/libs/Phone'
import { HeaderLink } from 'components/shared/Header/types'
import { Icon } from 'components/ui/Icon'
import { useRouter } from 'next/router'
import { FC, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useClickAway, useMount } from 'react-use'
import { useTheme } from 'styled-components'

import { Navbar, NavList, StyledFlex, Wrapper } from './styles'

interface Props {
  links: Array<HeaderLink>
  isOpen: boolean
  close: () => void
}

export const MobileMenu: FC<Props> = ({ links, close, isOpen }) => {
  const theme = useTheme()
  const router = useRouter()
  const [hasMounted, setHasMounted] = useState(false)
  const navbarRef = useRef(null)

  useClickAway(navbarRef, () => isOpen && close())

  useMount(() => setHasMounted(true))

  if (!hasMounted) return null

  return createPortal(
    <Wrapper isOpen={isOpen} data-testid='wrapper'>
      <Navbar isOpen={isOpen} ref={navbarRef}>
        <StyledFlex container justify='flex-end'>
          <Icon
            icon={theme.icons.core.Close}
            color={theme.palette.core.LIGHT_GRAY}
            onClick={close}
          />
        </StyledFlex>
        <NavList container direction='column' rowGap={24}>
          <Phone />
          {links.map(({ url, text, childLinks }) =>
            childLinks ? (
              <MobileDropdown key={text} text={text} links={childLinks} onClose={close} />
            ) : (
              <NavLink
                key={text}
                title={text}
                href={url}
                isActive={router.asPath === url}
                clickHandler={close}
              >
                {text}
              </NavLink>
            )
          )}
          <LanguageSelector />
        </NavList>
      </Navbar>
    </Wrapper>,
    document.body
  )
}
